import React from "react";
import LatestArrivalsMain from "../latestArrivals/LatestArrivalsMain";

const WeddingStoreMain = () => {
  return (
    <div>
      <LatestArrivalsMain
        tagId={95}
        pageTitle="Wedding Store"
        apiEndpoint="/tags/get_list_for_tag"
      />
    </div>
  );
};

export default WeddingStoreMain;
