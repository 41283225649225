import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer id="new">
        <div className="container">
          <div className="row-footer">
            <div className="left-sec">
              <div className="logo">
                <Link>
                  <img src="/images/topnavi.png" alt="Seashels Logo" />
                </Link>
              </div>
              <div className="ftr-menu">
                <ul>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/termsofservices">Terms of Services</Link>
                  </li>
                  <li>
                    <Link
                      to="https://global.seashels.com/original.html"
                      target="_blank"
                    >
                      Shipping & Returns
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/how-to-delete-your-account">
                      Seashels App Account Delete Guide
                    </Link>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="ftr-contact">
              <form action="">
                <div className="field group">
                  <div className="field-row">
                    <input type="text" placeholder="Your full name*" required />
                  </div>
                  <div className="field-row">
                    <input type="email" placeholder="Email *" required />
                  </div>
                </div>
                <div className="field">
                  <div className="field-row">
                    <input type="tel" placeholder="Telephone *" required />
                  </div>
                </div>
                <div className="field">
                  <div className="field-row">
                    <textarea placeholder="Message" required></textarea>
                  </div>
                </div>
                <div className="field">
                  <button type="submit">Submit</button>
                </div>
              </form>
            </div>
            <div className="test-model">
              <div className="content model-popup1">
                <div className="banner-content">
                  <div className="model-text">
                    <a href="#" style={{ display: "none" }}>
                      <img src="/images/topnavi.png" alt="Seashels logo" />
                    </a>
                    <p>
                      Discover latest arrivals in real time, talk to <br />
                      stores directly, watch their live streams <br /> and visit
                      them virtually
                    </p>
                    <p> Download the app </p>
                    <ul className="links">
                      <li>
                        <a
                          href="https://apps.apple.com/us/app/seashelsapp/id6463077820"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src="/images/app-store.svg" alt="App Store" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.seashels.discover&amp;pli=1"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src="/images/google-play.svg"
                            alt="Google Play Store"
                          />
                        </a>
                      </li>
                    </ul>
                    <div className="data_social social-ic">
                      <h3>Follow us on</h3>
                      <a href="https://www.instagram.com/seashels.modest?igsh=MXh5dWozajR6aGNzYg==">
                        <img src="/images/instagram.png" alt="Facebook" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="copy-right">
            <p>© 2025 Fractal Systems. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
