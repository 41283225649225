import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import api from "../../api";
import { useAuth } from "../../context/AuthContext";
import StoreDynamicLink from "./StoreDynamicLink";

import { isMobile } from "react-device-detect";
const StoreVideos = ({
  storeName,
  thumburldata,
  video_data,
  onProductClick,
  onProductClick1,
  loading,
  follow_status,
  storeUuid,
  storeSellers,
  storeId,
}) => {
  const [filteredSellers, setFilteredSellers] = useState([]);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [isFollowing, setIsFollowing] = useState(follow_status || false);
  const { tag_id, tag_type, store_uuid, video_id } = useParams();

  const [dimensions, setDimensions] = useState({
    width: "100%",
    height: isMobile ? "212px" : "375px",
  });

  useEffect(() => {
    const newHeight = isMobile ? "212px" : "375px";
    setDimensions({ width: "100%", minHeight: newHeight });
  }, []);
  useEffect(() => {
    if (video_data && video_id) {
      const matchedVideo = video_data.find(
        (video) => video.video_uuid === video_id
      );
      if (matchedVideo) {
        onProductClick1(matchedVideo.streaming_url, matchedVideo);
      }
    }
  }, [video_data, video_id]);

  const filterSellers = (sellers) => {
    return sellers.filter((seller) => seller.is_internal === 1);
  };

  useEffect(() => {
    if (!loading && storeSellers) {
      setFilteredSellers(filterSellers(storeSellers));
    }
  }, [storeSellers, loading]);

  const handleFollowToggle = async () => {
    try {
      const apiToken = localStorage.getItem("apiToken");
      const endpoint = isFollowing
        ? "/follow/unfollow_user"
        : "/follow/follow_user";
      const payload = {
        api_token: apiToken,
        [isFollowing ? "user_following_uuid" : "following_uuid"]: storeUuid,
      };

      const response = await api.post(endpoint, payload);

      if (response.status === 200 || response.status === 201) {
        const successStatus =
          response.data.status === 200 ||
          response.data.message === "Already following";
        if (successStatus) {
          setIsFollowing(!isFollowing);
        } else {
          alert(`Action failed: ${response.data.message}`);
        }
      } else if (response.status === 401) {
        logout();
        navigate("/home");
      } else {
        throw new Error(
          `HTTP error! status: ${response.status}, message: ${response.data.message}`
        );
      }
    } catch (error) {}
  };

  const handleShare = async () => {
    try {
      const dynamicLink = await StoreDynamicLink(
        storeId,
        storeName,
        thumburldata
      );

      if (navigator.share) {
        await navigator.share({
          url: dynamicLink,
        });
      } else {
        navigator.clipboard.writeText(dynamicLink);
      }
    } catch (error) {}
  };

  return (
    <div
      className={`store-videos ${
        isMobile ? "store-videos-mob" : "store-videos-web"
      }`}
    >
      <div className="container">
        {loading ? (
          <LoadingSkeleton />
        ) : (
          <>
            <div className="store-sec-videos">
              <h1>{storeName}</h1>
              <div className="follow-share-sec">
                <ul>
                  <li>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handleFollowToggle();
                      }}
                    >
                      <img
                        src="/images/add.png"
                        alt={isFollowing ? "Unfollow" : "Follow"}
                      />
                      {isFollowing ? "Unfollow" : "Follow"}
                    </a>
                  </li>
                  <li className="share">
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handleShare();
                      }}
                    >
                      <img src="/images/shop-share.svg" alt="Share" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            {video_data && video_data.length > 0 && (
              <ul
                className={
                  isMobile ? "horizontal-scroll social-carousel" : "desktop_cls"
                }
                // style={{
                //   gridTemplateColumns: isMobile ? "1fr 1fr" : "",
                // }}
              >
                {video_data.slice(0, 4).map((item, index) => (
                  <li
                    key={`${item.category_id}-${index}`}
                    data-vid_id={item.video_uuid}
                    className="item"
                  >
                    <div
                      className="img-box"
                      height={isMobile ? 275 : 300}
                      width={isMobile ? 170 : 300}
                    >
                      <Link
                        to="#"
                        onClick={() => onProductClick(item.streaming_url, item)}
                      >
                        <img
                          src={item.thumbnail_web}
                          alt={item.name}
                          style={dimensions}
                        />
                      </Link>
                    </div>
                    <p>{item.description}</p>
                  </li>
                ))}
              </ul>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const LoadingSkeleton = () => (
  <>
    <div className="store-sec-videos">
      <Skeleton
        height={isMobile ? 30 : 60}
        width={isMobile ? 150 : 300}
        style={{ margin: "20px 0px" }}
      />
      <div className="follow-share-sec">
        <Skeleton
          height={isMobile ? 32 : 50}
          width={isMobile ? 40 : 60}
          style={{
            marginRight: "10px",
            marginTop: isMobile ? "15px" : "25px",
          }}
        />
      </div>
    </div>
    <ul
      className={isMobile ? "horizontal-scroll social-carousel" : "desktop_cls"}
      style={{
        gridTemplateColumns: !isMobile ? "1fr 1fr 1fr 1fr" : "",
      }}
    >
      {Array.from({ length: 4 }).map((_, index) => (
        <li key={index} className="item">
          <div
            className="img-box"
            height={isMobile ? 275 : 300}
            width={isMobile ? 170 : 300}
          >
            <Skeleton
              height={isMobile ? 275 : 300}
              style={{ marginBottom: "10px" }}
            />
            <Skeleton height={20} />
          </div>
        </li>
      ))}
    </ul>
  </>
);

export default StoreVideos;
