import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import api from "../../api";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import LoadMore from "../LoadMore/LoadMore";
import { useAuth } from "../../context/AuthContext";
import { isMobile } from "react-device-detect";

const ITEMS_PER_PAGE = 54;

const Deals = ({ apiEndpoint = "/tags/get_edit_list", pageTitle = "" }) => {
  const { logout } = useAuth();
  const { tag_id, tag_type } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [products, setProducts] = useState([]);
  const [originalProducts, setOriginalProducts] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [activeTag, setActiveTag] = useState(null);

  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [error, setError] = useState(null);

  const [loadedItemCount, setLoadedItemCount] = useState(0);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    const savedPosition = localStorage.getItem("scrollPositionDeals");

    if (savedPosition) {
      window.scrollTo(0, parseInt(savedPosition, 10));
    }

    const saveScrollPosition = () => {
      localStorage.setItem("scrollPositionDeals", window.scrollY);
    };

    window.addEventListener("beforeunload", saveScrollPosition);

    return () => {
      window.removeEventListener("beforeunload", saveScrollPosition);
      saveScrollPosition();
    };
  }, []);

  useEffect(() => {
    const savedPosition = localStorage.getItem("scrollPositionDeals");

    if (savedPosition) {
      const parsedPosition = parseInt(savedPosition, 10);

      if (!isNaN(parsedPosition)) {
        window.scrollTo(0, parsedPosition);
      }
    }
  }, [location]);

  const fetchProducts = async (append = false) => {
    setLoadingMore(true);
    try {
      if (!append) setLoading(true);

      const api_token = localStorage.getItem("apiToken");
      const response = await api.get(apiEndpoint, {
        params: {
          api_token,
          tag_type: "edit",
          page: Math.floor(loadedItemCount / ITEMS_PER_PAGE) + 1,
          per_page: ITEMS_PER_PAGE,
        },
      });

      if (response.status === 401) {
        logout();
        navigate("/home");
        return;
      }

      const data = response.data;

      const processedProducts =
        data.edit_list?.map((item) => ({
          id: item.id,
          image: item.media_url_thumbnail,
          media_url: item.media_url,
          alt: item.tag_name,
          title: item.tag_name,
          tag_id: item.tag_id,
          type_data: item.type,
          button_text: item.button_text,
          button_screen: item.button_screen,
          heading_align: item.heading_align,
          heading_1: item.heading_1,
          button_color: item.button_color,
          sui: item.sui,
        })) || [];

      setProducts((prevProducts) =>
        append ? [...prevProducts, ...processedProducts] : processedProducts
      );

      if (!append) {
        setOriginalProducts(processedProducts);
      }

      setTagList([{ tag_id: null, tag_name: "All" }, ...data.tag_list]);
      setActiveTag(null);
      setLoadedItemCount((prev) => prev + processedProducts.length);
      setTotalItems(data.edit_items_total_count);
    } catch (err) {
      setError(err.message || "Failed to fetch products.");
    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, [apiEndpoint, tag_id, tag_type]);

  const handleTagFilter = (tag) => {
    setActiveTag(tag.tag_id);
    if (tag.tag_id === null) {
      setProducts(originalProducts);
    } else {
      setProducts(
        originalProducts.filter((product) => product.tag_id === tag.tag_id)
      );
    }
  };

  const handleProductClick = (type_id, tag_type) => {
    console.log(window.scrollY, "window.scrollY current");
    localStorage.setItem("scrollPositionDeals", window.scrollY);
    navigate(`/latest-arrivals/${type_id}/${tag_type}`);
  };

  const loadMoreProducts = () => {
    fetchProducts(true);
  };

  if (loading && loadedItemCount === 0) {
    return (
      <div className="main-content deals">
        <div className="pop-up-content">
          <div className="container">
            <ul>
              {Array.from({ length: 12 }).map((_, index) => (
                <li key={index} className="product-item">
                  <Skeleton
                    height={isMobile ? 520 : 542}
                    width={isMobile ? 350 : 370}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div className="main-content deals">
      <div className="container">
        <h2>{pageTitle}</h2>
        <div className="tag-filter" id="filter-products">
          <ul>
            {tagList.map((tag) => (
              <li
                key={tag.tag_id}
                className={tag.tag_id === activeTag ? "active" : ""}
                onClick={() => handleTagFilter(tag)}
              >
                {tag.tag_name}
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="pop-up-content">
        <div className="container">
          <ul>
            {products?.map((product) => (
              <li
                key={product.id}
                className="product-item"
                onClick={() =>
                  handleProductClick(product.tag_id, product.type_data)
                }
              >
                <img src={product.image} alt={product.alt} />
              </li>
            ))}
          </ul>
        </div>
      </div>

      {loadedItemCount < totalItems && (
        <LoadMore
          loadedItemCount={loadedItemCount}
          totalItems={totalItems}
          onLoadMore={loadMoreProducts}
          loadingMore={loadingMore}
        />
      )}
    </div>
  );
};

export default Deals;
