import React from "react";
import "./CheckoutFormSkelton.css";
const CheckoutFormSkelton = () => {
  return (
    <div className="skeleton-loading checkout">
      <div className="skeleton-row field-row">
        <div className="skeleton-input field"></div>
      </div>
      <div className="skeleton-row">
        <div className="skeleton-input"></div>
      </div>
      <div className="skeleton-row">
        <div className="skeleton-input"></div>
      </div>
      <div className="skeleton-row">
        <div className="skeleton-input"></div>
      </div>
      <div className="skeleton-row">
        <div className="skeleton-input"></div>
      </div>
      <div className="skeleton-row">
        <div className="skeleton-select"></div>
      </div>
      <div className="skeleton-row">
        <div className="skeleton-select"></div>
      </div>
      <div className="skeleton-row">
        <div className="skeleton-input"></div>
        <div className="skeleton-select"></div>
      </div>
      <div className="skeleton-button"></div>
    </div>
  );
};

export default CheckoutFormSkelton;
