import React, { useEffect, useState, useCallback } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import api from "../../api";
import { useAuth } from "../../context/AuthContext";
import { useCurrency } from "../../context/CurrencyContext";
import { useCart } from "../../context/CartContext";
import OwlCarousel from "react-owl-carousel";
import "./LiveSocial.css";
import Modal from "../modal/Modal";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { isMobile } from "react-device-detect";
import LazyLoadImage from "../LazyLoadImage";
import { debounce, throttle } from "lodash";
import WeddingStore from "./WeddingStore";
import BestSeller from "./BestSeller";
import LiveSocial from "./LiveSocial";
import VisitStoreDigitally from "./VisitStoreDigitally";
import LatestArrivals from "./LatestArrivals";

const CACHE_KEY = "homePageData";
const CACHE_DURATION = 3600000;

const HomePage = ({ style }) => {
  const { logout } = useAuth();
  const { setCurrencyPreference } = useCurrency();
  const navigate = useNavigate();
  const { setCartCount } = useCart();
  const loc = useLocation();
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isBackNavigation, setIsBackNavigation] = useState(false);
  const [dimensions, setDimensions] = useState({
    width: "100%",
    height: "auto",
  });
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [selectedProductLinks, setSelectedProductLinks] = useState([]);

  const handleScroll = throttle(() => {}, 100);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handlePopState = (event) => {
      if (event.state && event.state.idx === 0) {
        setIsBackNavigation(true);
      } else {
        setIsBackNavigation(false);
      }
    };

    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [loc]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.setItem("scrollPositionHome", window.scrollY);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const savedPosition = localStorage.getItem("scrollPositionHome");
    if (savedPosition && isBackNavigation) {
      setTimeout(() => {
        window.scrollTo(0, parseInt(savedPosition, 10));
      }, 100);
    }
  }, [isBackNavigation]);

  useEffect(() => {
    setDimensions(
      isMobile
        ? { width: "100%", minHeight: "580px" }
        : { width: "100%", minHeight: "630px" }
    );
  }, []);

  const settings = {
    items: 1,
    margin: 10,
    loop: true,
    autoplay: true,
    autoplayTimeout: 6000,
    dots: false,
    nav: false,
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      const cachedData = localStorage.getItem(CACHE_KEY);
      const currentTime = Date.now();

      // if (
      //   cachedData &&
      //   currentTime - JSON.parse(cachedData).timestamp < CACHE_DURATION
      // ) {
      //   setData(JSON.parse(cachedData).data);
      //   setLoading(false);
      //   return;
      // }

      try {
        const api_token = localStorage.getItem("apiToken") || "";
        const clientId = localStorage.getItem("clientId");
        const userRole = localStorage.getItem("userRole");

        const adjustedToken =
          userRole === "guest" && !api_token.startsWith("guest_")
            ? `guest_${api_token}`
            : api_token;
        const country_code = localStorage.getItem("countryCode") || "US";
        const currency_code = localStorage.getItem("currencyCode") || "$";

        const response = await api.post("/user/user_home_web", {
          api_token: adjustedToken,
          app_type: "web",
          app_version: "1.0",
          country_code: country_code,
          currency_code: currency_code,
          advertiser_id: "",
          device_token: clientId,
        });
        setData(response.data);

        localStorage.setItem(
          CACHE_KEY,
          JSON.stringify({ data: response.data, timestamp: currentTime })
        );

        localStorage.setItem(
          "userCountryCode",
          response.data.user_data.base_country_code
        );
        localStorage.setItem("userUuid", response.data.user_data.user_uuid);
        if (response.data.status === 200 && userRole != "guest") {
          const cartResponse = await api.post("/cart/create_cart", {
            api_token: adjustedToken,
          });
          const cartData = cartResponse?.data?.cart_data;
          if (cartData) {
            const { cart_id, order_num, item_count } = cartData;
            localStorage.setItem("cart_id", cart_id);
            localStorage.setItem("order_num", order_num);
            localStorage.setItem("item_count", item_count);
            setCartCount(Number(item_count) || 0);
          }
        } else if (response.data.status === 401) {
          logout();
          navigate("/home");
        }
      } catch (error) {
        setError(error.message || "An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };
    if (localStorage.getItem("apiToken")) {
      fetchData();
    }
  }, [logout, setCartCount, navigate]);

  const openModal = (videoUrl, productLinks) => {
    const productLinksArray = Array.isArray(productLinks.product_links)
      ? productLinks.product_links
      : [productLinks.product_links];
    const transformedVideos = productLinksArray.map((link) => ({
      ...productLinks,
      product_sui: link,
    }));
    setSelectedVideo(videoUrl);
    setSelectedProductLinks(transformedVideos[0]);
  };

  const closeModal = () => {
    setSelectedVideo(null);
    setSelectedProductLinks([]);
  };

  const handleBannerClick = useCallback(
    (type_id, tag_type) => {
      localStorage.setItem("scrollPositionHome", window.scrollY);
      navigate(`/latest-arrivals/${type_id}/${tag_type}`);
    },
    [navigate]
  );

  const handleBannerClick1 = useCallback(
    (type_id, tag_type) => {
      localStorage.setItem("scrollPositionHome", window.scrollY);
      navigate(`/latest-arrivals/${type_id}/${tag_type}`);
    },
    [navigate]
  );

  return (
    <div className="main-content">
      {loading ? (
        <Skeleton
          style={{
            width: "100%",
            height: isMobile ? "580px" : "645px",
          }}
          count={1}
          enableAnimation={false}
        />
      ) : (
        data.slider_1 && (
          <section className="banner">
            <OwlCarousel
              className="banner-carousel owl-carousel owl-theme"
              {...settings}
            >
              {data.slider_1.map((banner, index) => (
                <div
                  key={index}
                  className="item"
                  onClick={() => handleBannerClick(banner.type_id, banner.type)}
                >
                  <Link to="#" className="banner-btn">
                    <LazyLoadImage
                      style={{
                        width: "100%",
                        height: isMobile ? "580px" : "645px",
                      }}
                      thumbnailSrc={
                        isMobile
                          ? banner.media_url_web_mobile_thumb
                          : banner.media_url_web_desk_thumb
                      }
                      fullImageSrc={
                        isMobile
                          ? banner.media_url_web_mobile
                          : banner.media_url_web_desk
                      }
                      alt={"Banner Image"}
                    />
                  </Link>
                </div>
              ))}
            </OwlCarousel>
          </section>
        )
      )}

      {loading ? (
        <>
          <BestSeller loading />
          <WeddingStore loading />

          <LiveSocial loading />
          <VisitStoreDigitally loading />
          <LatestArrivals loading />
        </>
      ) : (
        <>
          <BestSeller products={data.bestseller} />
          <section className="wedding">
            <OwlCarousel
              className="banner-carousel owl-carousel owl-theme"
              {...settings}
            >
              {data.slider_2.map((banner, index) => (
                <div
                  key={index}
                  className="item"
                  onClick={() =>
                    handleBannerClick1(banner.type_id, banner.type)
                  }
                >
                  <Link to="#" className="banner-btn">
                    <LazyLoadImage
                      style={{
                        width: "100%",
                        height: isMobile ? "580px" : "645px",
                      }}
                      thumbnailSrc={
                        isMobile
                          ? banner.media_url_web_mobile_thumb
                          : banner.media_url_web_desk_thumb
                      }
                      fullImageSrc={
                        isMobile
                          ? banner.media_url_web_mobile
                          : banner.media_url_web_desk
                      }
                      alt={"Wedding Store Banner"}
                    />
                  </Link>
                </div>
              ))}
            </OwlCarousel>
          </section>

          {data.live_social && (
            <section className="live">
              <div className="container">
                <h2 className="read-more">{data.live_social.heading}</h2>
                <div className="social-carousel">
                  {data.live_social.data.carousel.map((item, index) => (
                    <div key={index} className="item">
                      <div
                        className="img-box"
                        style={{ minHeight: isMobile ? "260px" : "444px" }}
                      >
                        <img
                          src={item.video_thumbnail_web}
                          alt={`Social Image ${index + 1}`}
                          onClick={() => openModal(item.video_url, item)}
                        />
                      </div>
                      <div className="content">
                        <h3>
                          <Link to={`/visit-store/${item.store_uuid}`}>
                            {item.store_name}
                          </Link>
                        </h3>
                        <h4>{item.video_description}</h4>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          )}

          {data.slider_3 && (
            <section className="store">
              <div className="visit-store-digitally">
                <h2>
                  {!loading && <Link to="/stores" className="read-more"></Link>}
                </h2>
                <div className="carousel-section">
                  {loading ? (
                    <Skeleton
                      style={{
                        width: "100%",
                        height: isMobile ? "400px" : "645px",
                      }}
                      count={1}
                      enableAnimation={false}
                    />
                  ) : (
                    data.slider_3.length > 0 && (
                      <OwlCarousel
                        className="store-carousel owl-carousel owl-theme"
                        {...settings}
                      >
                        {data.slider_3.map((banner, index) => (
                          <div key={index} className="item">
                            <LazyLoadImage
                              style={{ minHeight: "80px" }}
                              thumbnailSrc={
                                isMobile
                                  ? banner.media_url_web_mobile_thumb
                                  : banner.media_url_web_desk_thumb
                              }
                              fullImageSrc={
                                isMobile
                                  ? banner.media_url_web_mobile
                                  : banner.media_url_web_desk
                              }
                              alt="store"
                            />
                          </div>
                        ))}
                      </OwlCarousel>
                    )
                  )}
                </div>
              </div>
            </section>
          )}
          {data.latest_arrival && (
            <LatestArrivals products={data.latest_arrival} />
          )}
        </>
      )}

      {selectedVideo && (
        <Modal
          closeModal={closeModal}
          videoUrl={selectedVideo}
          productLinks={selectedProductLinks}
        />
      )}
    </div>
  );
};

export default HomePage;
