import React, { useEffect, useRef, useState } from "react";
import Hls from "hls.js";
import "./Modal.css";
import api from "../../api";
import shareVideosLink from "./shareVideosLink";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const ModalDetails = ({ isOpen, onClose, videoUrl, videoData }) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(true);
  const api_token = localStorage.getItem("apiToken");
  const [productDetails, setProductDetails] = useState([]);
  const [imageData, setImageData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchProductDetails = async () => {
      if (videoData) {
        const { product_sui, store_uuid, description, store_name } = videoData;

        if (!product_sui || !store_uuid) {
          return;
        }

        try {
          const response = await api.get("/sku/get_multiple_sku", {
            params: {
              api_token,
              sui_list: product_sui,
              store_uuid,
            },
          });

          if (response.status === 200) {
            setProductDetails(response.data.data.data);
            setImageData(response.data.data.data[0]?.product_url_thumb);
          } else {
          }
        } catch (error) {}
      }
    };

    fetchProductDetails();
  }, [videoData, api_token]);

  useEffect(() => {
    const videoElement = videoRef.current;
    let hls;

    if (isOpen && videoUrl && videoElement) {
      setIsLoading(true);
      if (Hls.isSupported() && videoUrl.includes(".m3u8")) {
        hls = new Hls();
        hls.loadSource(videoUrl);
        hls.attachMedia(videoElement);
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          videoElement
            .play()
            .then(() => {
              setIsLoading(false);
            })
            .catch((err) => {});
        });
      } else {
        videoElement.src = videoUrl;
        videoElement
          .play()
          .then(() => {
            setIsLoading(false);
          })
          .catch((err) => {});

        videoElement.onloadedmetadata = () => {
          setIsLoading(false);
        };
      }

      const handleLoop = () => {
        videoElement.currentTime = 0;
        videoElement
          .play()
          .catch((err) => console.error("Error playing video:", err));
      };

      videoElement.onended = handleLoop;

      videoElement.muted = isMuted;

      return () => {
        if (hls) {
          hls.destroy();
        }
        videoElement.pause();
        videoElement.onended = null;
      };
    }
    if (!isOpen && videoElement) {
      videoElement.muted = true;
      setIsPlaying(false);
      setIsMuted(true);
    }
  }, [isOpen, videoUrl, isMuted]);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.muted = isMuted;
    }
  }, [isMuted]);

  const handlePlayPause = () => {
    const videoElement = videoRef.current;
    if (isPlaying) {
      videoElement.pause();
    } else {
      videoElement
        .play()
        .catch((err) => console.error("Error playing video:", err));
    }
    setIsPlaying(!isPlaying);
  };

  const handleMuteUnmute = () => {
    setIsMuted((prevMuted) => !prevMuted);
  };

  const handleShare = async () => {
    const { video_uuid, store_uuid, store_logo, store_name, description } =
      videoData;

    try {
      const imagedata = imageData;
      const dynamicLink = await shareVideosLink(
        store_name,
        description,
        video_uuid,
        store_uuid,
        imagedata
      );

      if (navigator.share) {
        await navigator.share({
          url: dynamicLink,
        });
      }
    } catch (error) {}
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay video-frame" onClick={onClose}>
      <div
        className="modal-content video-play-data"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="video-product-container">
          <div className="video-section">
            <video
              ref={videoRef}
              autoPlay
              muted
              loop
              playsInline
              preload="auto"
              className="video-player"
            />
            <div className="custom-controls">
              <button className="control-btn" onClick={onClose}>
                <img src="/images/close.svg" alt="Close" />
              </button>

              <button
                className="control-btn"
                onClick={(e) => {
                  e.preventDefault();
                  handleShare();
                }}
              >
                <img src="/images/share.svg" alt="Share video" />
              </button>

              <button className="control-btn" onClick={handleMuteUnmute}>
                <img
                  src={
                    isMuted
                      ? "/images/ic_volume_off.png"
                      : "/images/ic_volume_on.png"
                  }
                  alt={isMuted ? "Unmute" : "Mute"}
                />
              </button>
            </div>
          </div>

          <div className="products-section">
            {productDetails.length > 0 &&
              productDetails.map((product, index) => (
                <a
                  href={`/product/${product.SUI}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                  className="product-item-circle"
                >
                  <img
                    src={product.product_url_thumb}
                    alt={product.product_title}
                    className="product-image-circle"
                  />
                </a>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalDetails;
