import React, { useEffect, useRef, useState } from "react";
import { useClientInfo } from "../../context/ClientInfoContext";

import { useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import { GetCountries, GetState, GetCity } from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import CheckoutFormSkelton from "./CheckoutFormSkelton";
import CountryCodeString from "../currencySelector/countryCodes.json";

const CheckoutForm = ({ existingAddress, addAddress, loading }) => {
  const { ipAddress, locationInfo } = useClientInfo();
  const getDialCode = (countryCode) => {
    const dialCode = Object.keys(CountryCodeString).find(
      (key) => CountryCodeString[key] === countryCode
    );
    return dialCode || "1";
  };

  const [phoneValue, setPhoneValue] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [countryId, setCountryId] = useState(0);
  const [countryName, setCountryName] = useState("");
  const [phoneFlagValue, setPhoneFlagValue] = useState("");
  const [stateId, setStateId] = useState("");
  const [cityId, setCityId] = useState("");
  const [cityName, setCityName] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [countriesList, setCountriesList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [error, setError] = useState(null);
  const [dialCode, setDialCode] = useState("+1");
  const [phoneValueData, setPhoneValueData] = useState("");
  useEffect(() => {
    if (locationInfo && locationInfo.country_code) {
      setPhoneFlagValue(locationInfo.country_code.toLowerCase());
    }
  }, [locationInfo]);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      last_name: "",
      email: "",
      phone: "",
      address_1: "",
      address_2: "",
      state: "",
      zipcode: "",
      city: "",
      country: "",
    },
  });
  const formRef = useRef(null);

  useEffect(() => {
    if (existingAddress?.user_address?.length > 0) {
      const existing = existingAddress.user_address[0] || {};
      setPostalCode(existing.zipcode || "");
      setCountryCode(existing.country_code || "");
      setCountryName(existing.country_name || "");
      setStateId(existing.state || "");
      setCityName(existing.city || "");
      if (existing.phone.includes("-")) {
        const [phoneflag, phoneNumber] = existing.phone.split("-");

        const dialCode1 = getDialCode(phoneflag);
        setPhoneValue(dialCode1 + phoneNumber || "");
      } else {
        setPhoneFlagValue("us");
        setPhoneValue(existing.phone || "");
      }
      setValue("city", existing.city);
      reset({
        name: existing.name || "",
        last_name: existing.last_name || "",
        email: existing.user_address_email || "",
        address_1: existing.address_1 || "",
        address_2: existing.address_2 || "",
        state: existing.state || "",
        zipcode: existing.zipcode || "",
        city: existing.city || "",
        country: existing.country_name || "",
      });
      GetCountries().then((result) => {
        const prioritizedCountries = ["US", "GB"];
        const reorderedCountries = [
          ...result.filter((country) =>
            prioritizedCountries.includes(country.iso2)
          ),
          ...result.filter(
            (country) => !prioritizedCountries.includes(country.iso2)
          ),
        ];

        setCountriesList(reorderedCountries);

        const matchedCountry = reorderedCountries.find(
          (country) => country.iso2 === existing.country_code
        );
        if (matchedCountry) {
          setValue("country", matchedCountry.iso2);
          setCountryId(matchedCountry.id);
          setCountryCode(matchedCountry.iso2);
          GetState(matchedCountry.id).then((stateResult) => {
            setStateList(stateResult);
            setValue("state", existing.state || "");

            const matchedState = stateResult.find((state) =>
              state.name.includes(existing.state)
            );
            if (matchedState) {
              setValue("state", matchedState.name);
              setStateId(matchedState.name);
            }
          });
        }
      });
    } else {
      GetCountries()
        .then((result) => {
          const prioritizedCountries = ["US", "GB"];
          const reorderedCountries = [
            ...result.filter((country) =>
              prioritizedCountries.includes(country.iso2)
            ),
            ...result.filter(
              (country) => !prioritizedCountries.includes(country.iso2)
            ),
          ];

          setCountriesList(reorderedCountries);

          const matchedCountry = reorderedCountries.find(
            (country) => country.iso2 === countryCode
          );

          if (matchedCountry) {
            setValue("country", matchedCountry.iso2);
            setCountryId(matchedCountry.id);
            setCountryCode(matchedCountry.iso2);
            GetState(matchedCountry.id)
              .then((stateResult) => {
                setStateList(stateResult);
              })
              .catch((error) => {
                console.error("Error fetching states:", error);
                setStateList([]);
              });
          } else {
            setCountryId(null);
            setStateList([]);
          }
        })
        .catch((error) => {
          setCountriesList([]);
        });
    }
  }, [existingAddress, reset, setValue]);

  const handlePhoneChange = (value, countryData) => {
    setValue(value);
    const currentDialCode = countryData.dialCode;
    const countryCode = countryData.countryCode;

    const trimmedValue = value.startsWith(currentDialCode)
      ? value.substring(currentDialCode.length)
      : value;
    const phoneValueData = countryCode.toUpperCase() + "-" + trimmedValue;
    setPhoneValue(value);
    setPhoneValueData(phoneValueData);
    setDialCode(currentDialCode);
  };
  const handleCountryChange = (country) => {
    if (country) {
      setCountryId(country.id);
      setCountryCode(country.iso2);
      setCountryName(country.name);
      setValue("country", country.iso2);
      setStateId("");
      setCityId("");
      setCityList([]);
      setCityName("");

      GetState(country.id).then((result) => {
        setStateList(result);
        setValue("state", "");
        setValue("city", "");
        setValue("zipcode", "");
      });
      reset((prev) => ({
        ...prev,
        state: "",
        city: "",
        country: country.iso2,
      }));
    }
  };

  const handleStateChange = (state) => {
    if (state) {
      setStateId(state.name);
      setCityName("");
      setCityId("");
      setCityList([]);
      GetCity(countryId, state.id).then((result) => {
        setCityList(result);
        setValue("city", "");
      });
      reset((prev) => ({
        ...prev,
        city: "",
        state: state.name,
      }));
    }
  };
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (postalCode && postalCode.length >= 4) {
        handleAddressFetched(postalCode);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [postalCode]);
  const handleAddressFetched = async (postalCode) => {
    if (!postalCode) {
      setError("Postal code is empty");
      return;
    }
    if (postalCode.length < 4) {
      return;
    }

    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?postalcode=${postalCode}&countrycodes=${countryCode.toLocaleLowerCase()}&format=json&addressdetails=1`
      );
      const data = await response.json();

      if (data.length > 0) {
        setError(null);
        const matchedData = data.find((item) => {
          const { address } = item;
          const cityName =
            address?.city ||
            address?.county ||
            address?.village ||
            address?.town ||
            address?.suburb ||
            address?.state_district ||
            null;
          const stateName = address?.state || null;
          const countryName = address?.country || null;
          return countryName && stateName && cityName;
        });

        const { address } = matchedData;
        const cityName =
          address?.city ||
          address?.village ||
          address?.town ||
          address?.suburb ||
          address?.state_district ||
          null;
        const districtName =
          address?.state_district || address?.district || null;
        const stateName = address?.state || null;
        const countryName = address?.country || null;
        const country_code = address?.country_code || null;

        GetCountries().then((result) => {
          const prioritizedCountries = ["US", "GB"];
          const reorderedCountries = [
            ...result.filter((country) =>
              prioritizedCountries.includes(country.iso2)
            ),
            ...result.filter(
              (country) => !prioritizedCountries.includes(country.iso2)
            ),
          ];

          setCountriesList(reorderedCountries);

          const matchedCountry = reorderedCountries.find(
            (country) => country.name === countryName
          );

          if (matchedCountry) {
            setValue("country", matchedCountry.iso2);
            setCountryId(matchedCountry.id);
            setCountryCode(matchedCountry.iso2);
            GetState(matchedCountry.id).then((stateResult) => {
              setStateList(stateResult);
              const matchedState = stateResult.find(
                (state) => state.name === stateName
              );

              if (matchedState) {
                setValue("state", matchedState.name);
                setStateId(matchedState.name);

                GetCity(matchedCountry.id, matchedState.id).then(
                  (CityResult) => {
                    setCityList(CityResult);
                    setValue("city", cityName || "");
                    const matchedCity = CityResult.find(
                      (city) => city.name === cityName
                    );

                    if (matchedCity) {
                      setValue("city", matchedCity.name);
                      setCityId(matchedCity.id);
                      setCityName(matchedCity.name);
                    }
                  }
                );
              } else {
                setValue("state", "");
              }
            });
          } else {
            setValue("country", "");
          }
        });
      } else {
        setError("No results found");
      }
    } catch (err) {
      setError("Error fetching location data");
    }
  };

  const onSubmit = async (data) => {
    data.phone = phoneValueData || phoneValue;
    data.country_code = countryCode;
    data.country = countryName;
    data.city = cityName;

    if (existingAddress?.user_address?.length > 0) {
      const existing = existingAddress.user_address[0] || {};
      const isUpdate = {
        address_id: existing.address_id || "",
        ...data,
      };
      await addAddress(isUpdate);
    } else {
      await addAddress(data);
    }

    if (window.innerWidth < 768) {
      setTimeout(() => {
        if (formRef.current) {
          formRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
          });
        }
      }, 100);
    }
  };
  const getCountryFromLocale = () => {
    const locale = new Intl.DateTimeFormat().resolvedOptions().locale;
    const country = locale.split("-")[1];
    return country ? country.toUpperCase() : null;
  };
  useEffect(() => {
    const delayHandler = setTimeout(() => {
      const countryCodedata = countryCode
        ? countryCode
        : getCountryFromLocale();

      if (countryCodedata) {
        const selectedCountry = countriesList.find(
          (country) => country.iso2 === countryCodedata
        );
        setCountryName(selectedCountry?.name);
      }
    }, 2000);

    return () => clearTimeout(delayHandler);
  }, [countryCode]);
  const handleCityChange = (e) => {
    const value = e.target.value;
    setCityName(value);
    setValue("city", value);
  };

  return (
    <div className="checkout-form">
      {loading ? (
        <CheckoutFormSkelton />
      ) : (
        <form className="checkout" onSubmit={handleSubmit(onSubmit)}>
          <div className="field-row two">
            <div className="field">
              <input
                type="text"
                placeholder="First Name"
                {...register("name", { required: true })}
              />
              {errors.name && (
                <p className="errorMsg">First Name is required.</p>
              )}
            </div>
            <div className="field">
              <input
                type="text"
                placeholder="Last Name"
                {...register("last_name", { required: true })}
              />
              {errors.name && (
                <p className="errorMsg">Last Name is required.</p>
              )}
            </div>
          </div>

          <div className="field-row">
            <div className="field">
              <input
                type="text"
                placeholder="Email"
                {...register("email", {
                  required: true,
                  pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                })}
              />
              {errors.email && <p className="errorMsg">Email is required.</p>}
              {errors.email?.type === "pattern" && (
                <p className="errorMsg">Email is not valid.</p>
              )}
            </div>
          </div>

          <div className="field-row">
            <div className="field">
              <PhoneInput
                country={phoneFlagValue ? phoneFlagValue : "us"}
                value={phoneValue}
                onChange={handlePhoneChange}
                inputStyle={{ width: "100%" }}
                disabledCountryCode
                inputProps={{
                  name: "phone",
                  autoComplete: "off",
                }}
              />
              {errors.phone && (
                <p className="errorMsg">Phone number is required.</p>
              )}
            </div>
          </div>

          <div className="field-row">
            <div className="field">
              <input
                type="text"
                placeholder="Address 1"
                {...register("address_1", { required: true })}
              />
              {errors.address_1 && (
                <p className="errorMsg">Address 1 is required.</p>
              )}
            </div>
          </div>

          <div className="field-row">
            <div className="field">
              <input
                type="text"
                placeholder="Address 2"
                {...register("address_2")}
              />
            </div>
          </div>

          <div className="field-row">
            <div className="field">
              <select
                {...register("country", { required: true })}
                onChange={(e) => {
                  const selectedIndex = e.target.selectedIndex;
                  const selectedValue = e.target.value;
                  if (selectedValue === "") {
                    handleCountryChange("");
                  } else {
                    handleCountryChange(countriesList[selectedIndex - 1]);
                  }
                }}
                value={countryCode.toUpperCase()}
              >
                <option value="">Select Country</option>
                {countriesList.map((item, index) => (
                  <option key={index} value={item.iso2}>
                    {item.name}
                  </option>
                ))}
              </select>
              {errors.country && (
                <p className="errorMsg">Country is required.</p>
              )}
            </div>
          </div>
          <div className="field-row">
            <div className="field">
              <input
                type="text"
                placeholder="Postal Code"
                name="zipcode"
                {...register("zipcode", { required: true })}
                onChange={(e) => handleAddressFetched(e.target.value)}
              />
              {errors.zipcode && (
                <p className="errorMsg">Postal Code is required.</p>
              )}
            </div>
          </div>
          <div className="field-row">
            <div className="field">
              <select
                {...register("state", { required: true })}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  const selectedIndex = e.target.selectedIndex;
                  if (selectedValue === "") {
                    handleStateChange(null);
                  } else {
                    handleStateChange(stateList[selectedIndex - 1]);
                  }
                }}
                value={stateId}
              >
                <option value="">Select State</option>
                {stateList.map((item, index) => (
                  <option key={index} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </select>
              {errors.state && <p className="errorMsg">State is required.</p>}
            </div>
          </div>

          <div className="field-row">
            <div className="field">
              <input
                type="text"
                placeholder="City"
                value={cityName}
                {...register("city", { required: true })}
                onChange={handleCityChange}
              />
              {errors.city && <p className="errorMsg">City is required.</p>}
            </div>
          </div>

          <button type="submit" ref={formRef}>
            {existingAddress?.user_address?.length
              ? "Update Address"
              : "Save and Continue"}
          </button>
        </form>
      )}
    </div>
  );
};

export default CheckoutForm;
