import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./LatestArrivals.css";
import LazyLoadImage from "../LazyLoadImage";
import { isMobile } from "react-device-detect";
const BestSeller = ({ products = {}, loading, style }) => {
  const navigate = useNavigate();
  const [dimensions, setDimensions] = useState({
    width: "100%",
    height: isMobile ? "212px" : "375px",
  });

  useEffect(() => {
    const newHeight = isMobile ? "212px" : "375px";
    setDimensions({ width: "100%", minHeight: newHeight });
  }, []);

  const settings = {
    items: 5,
    margin: 10,
    loop: true,
    autoPlay: false,
    autoplaytimeout: 4000,
    dots: "true",
    responsive: {
      0: { items: 2 },
      768: { items: 3 },
      1024: { items: 4 },
      1200: { items: 5 },
    },
  };

  const carouselItems = products.data?.carousel || [];
  const heading = products.heading || "Latest Arrivals";
  const handleBannerClick1 = (type_id, tag_type) => {
    localStorage.setItem("scrollPositionHome", window.scrollY);

    navigate(`/latest-arrivals/${type_id}/${tag_type}`);
  };
  const handleBannerClick2 = (storeUuid) => {
    localStorage.setItem("scrollPositionHome", window.scrollY);

    navigate(`/visit-store/${storeUuid}`);
  };
  const handleProductClick = (sui) => {
    localStorage.setItem("scrollPositionHome", window.scrollY);

    navigate(`/product/${sui}`);
  };
  return (
    <section className="latest">
      <div className="container">
        {loading ? (
          <>
            <Skeleton
              width={150}
              height={40}
              style={{ marginBottom: "10px" }}
              enableAnimation={false}
            />

            <div className="carousel-skeleton social-carousel">
              {[...Array(6)].map((_, index) => (
                <div key={index} className="item">
                  <div className="img-box">
                    <Skeleton
                      height={350}
                      width={250}
                      enableAnimation={false}
                    />
                  </div>
                  <div className="content">
                    <Skeleton
                      width={100}
                      height={30}
                      style={{ marginTop: "10px" }}
                      enableAnimation={false}
                    />
                    <Skeleton width={150} height={40} enableAnimation={false} />
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
            <h2
              onClick={() =>
                handleBannerClick1(
                  carouselItems[0]?.tag_id,
                  carouselItems[0]?.tag_type
                )
              }
              className="read-more"
            >
              {heading}
              <Link
                onClick={() =>
                  handleBannerClick1(
                    carouselItems[0]?.tag_id,
                    carouselItems[0]?.type
                  )
                }
                className="read-more"
              ></Link>
            </h2>
            <div className="social-carousel" {...settings}>
              {carouselItems.length > 0 ? (
                carouselItems.map((product, index) => (
                  <div key={index} className="item">
                    <div
                      className="img-box"
                      style={dimensions}
                      onClick={() => handleProductClick(product.sui)}
                    >
                      <LazyLoadImage
                        style={dimensions}
                        thumbnailSrc={product.image_url_thumb}
                        fullImageSrc={product.image_url}
                        alt={product.product_title}
                      />
                      {(product.in_stock === 0 || product.in_stock === "0") && (
                        <span className="sold-out">Sold Out</span>
                      )}
                    </div>
                    <div className="content">
                      <h4
                        onClick={() => handleBannerClick2(product.store_uuid)}
                      >
                        {product.store_name}
                      </h4>
                      <div className="price">
                        <span>
                          <Link to={`/product/${product.sui}`}>
                            {isMobile
                              ? `${product.product_title}`
                              : product.product_title}
                          </Link>
                        </span>
                        <div className="price-content-sec">
                          {product.display_total_price !==
                            product.display_final_price && (
                            <span className="strike_price">
                              {`${product.display_currency_symbol}${product.display_total_price}`}
                            </span>
                          )}
                          <span className="final_price">
                            {`${product.display_currency_symbol}${product.display_final_price}`}
                          </span>
                          {product.display_total_price !==
                            product.display_final_price && (
                            <span className="discount">
                              {`${product.display_discount}% off`}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>No products available.</p>
              )}
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default BestSeller;
