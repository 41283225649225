// src/context/ClientInfoContext.js
import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";

const ClientInfoContext = createContext();

export const ClientInfoProvider = ({ children }) => {
  const [ipAddress, setIpAddress] = useState(null);
  const [locationInfo, setLocationInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchClientInfo = async () => {
      try {
        const ipResponse = await axios.get("https://api.ipify.org?format=json");
        const ip = ipResponse?.data?.ip;
        setIpAddress(ip);

        if (ip) {
          const locationResponse = await axios.get(
            `https://get.geojs.io/v1/ip/geo/${ip}.json`
          );

          setLocationInfo(locationResponse.data);
        }
      } catch (err) {
        setError("Failed to fetch IP or location information");
      } finally {
        setLoading(false);
      }
    };

    fetchClientInfo();
  }, []);

  if (loading) {
    return null;
  }

  return (
    <ClientInfoContext.Provider
      value={{ ipAddress, locationInfo, loading, error }}
    >
      {children}
    </ClientInfoContext.Provider>
  );
};

export const useClientInfo = () => {
  return useContext(ClientInfoContext);
};
