// components/StoreBanner.js
import React, { useState, useEffect } from "react";

import Skeleton from "react-loading-skeleton";
import LazyLoadImage from "../LazyLoadImage";
import { isMobile } from "react-device-detect";

const StoreBanner = ({ bannerUrl, thumburldata, loading }) => {
  const [dimensions, setDimensions] = useState({
    width: "100%",
    height: isMobile ? "212px" : "375px",
  });

  useEffect(() => {
    const newHeight = isMobile ? "212px" : "375px";
    setDimensions({ width: "100%", minHeight: newHeight });
  }, []);
  return (
    <div className="store-banner">
      {loading ? (
        <Skeleton height={530} />
      ) : (
        <LazyLoadImage
          style={dimensions}
          thumbnailSrc={thumburldata}
          fullImageSrc={bannerUrl}
          alt="store image"
        />
      )}
    </div>
  );
};

export default StoreBanner;
