// AlertPopup.js
import React from "react";
import Alert from "./Alert";
import "./AlertPopup.css";

const AlertPopup = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="alert-popup-overlay">
      <div className="alert-popup-content">
        <button className="alert-popup-close" onClick={onClose}>
          X
        </button>
        <div className="alert-popup-body">
          <Alert />
        </div>
      </div>
    </div>
  );
};

export default AlertPopup;
