// src/routes/Routes.js
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ProtectedRoute from "../components/profile/ProtectedRoute";
import HomePage from "../components/home/HomePage";
import UserLogin from "../components/login/UserLogin";
import ResetPassword from "../components/login/ResetPassword";
import CheckIp from "../components/CheckIp";
import AuthPage from "../components/AuthPage";
import GuestLogin from "../components/GuestLogin";
import AlertPopup from "../components/alert/AlertPopup";
import Alert from "../components/alert/Alert";
import Chat from "../components/Chat";
import Tab1 from "../components/Tab1";
import Tab2 from "../components/Tab2";
import Tab3 from "../components/Tab3";
import Pagination2 from "../components/pagination/Pagination2";
import LiveSocialMain from "../components/LiveSocialMain";
import LatestArrivalsMain from "../components/latestArrivals/LatestArrivalsMain";
import SeashelsEdits from "../components/deals/Deals";
import WeddingStoreMain from "../components/home/WeddingStoreMain";
import ProductDetails from "../components/productDetails/ProductDetails";
import StoreProfile from "../components/storeProfile/StoreProfile";
import StoreListing from "../components/storeProfile/StoreListing";
import PrivacyPolicy from "../components/PrivacyPolicy";
import TermsOfServeices from "../components/TermsOfServeices";
import DeleteYourAccount from "../components/DeleteYourAccount";
import Cart from "../components/cart/Cart";
import Coupon from "../components/coupon/Coupon";
import Checkout from "../components/checkout/Checkout";
import PaymentSuccess from "../components/checkout/PaymentSuccess";
import MyOrder from "../components/MyOrder";
import UserOrder from "../components/UserOrder";
import OrderDetails from "../components/myOrder/OrderDetails";
import Gift from "../components/Gift";
import Profile from "../components/profile/Profile";
import ProductList from "../components/latestArrivals/ProductList";
import CategoryList from "../components/CategoryList";
import Account from "../components/Account";
import Shop from "../components/shop/Shop";
import MyProfileFavorites from "../components/MyProfileFavorites";
import StripeContainer from "../components/stripe/StripeContainer";
import { CheckoutProvider } from "../context/CheckoutContext";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<UserLogin />} />
      <Route path="/stripe-form" element={<StripeContainer />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/checkip" element={<CheckIp />} />
      <Route path="/user" element={<AuthPage />} />
      <Route path="/guest-login" element={<GuestLogin />} />

      <Route path="/alertPopup" element={<AlertPopup />} />
      <Route path="/alert" element={<Alert />} />
      <Route path="/chat/*" element={<Chat />} />
      <Route path="/chat/tab-1/*" element={<Tab1 />} />
      <Route path="/chat/tab-2/*" element={<Tab2 />} />
      <Route path="/chat/tab-3/*" element={<Tab3 />} />

      <Route path="/pagination2" element={<Pagination2 />} />
      <Route path="/live-social" element={<LiveSocialMain />} />

      <Route path="/latest-arrivals" element={<LatestArrivalsMain />} />
      <Route
        path="/latest-arrivals/:tag_id/:tag_type"
        element={<LatestArrivalsMain />}
      />
      <Route path="/product/:sui" element={<ProductDetails />} />
      <Route path="/cart" element={<Cart />} />
      <Route path="/coupon-list" element={<Coupon />} />
      <Route
        path="/coupon-list/:coupon_owner_id/:order_id"
        element={<Coupon />}
      />
      <Route
        path="/checkout"
        element={
          <CheckoutProvider>
            <Checkout />
          </CheckoutProvider>
        }
      />
      <Route path="/payment-success" element={<PaymentSuccess />} />
      <Route path="/payment-success/:order_id" element={<PaymentSuccess />} />
      <Route path="/payment-details/:orderId" element={<OrderDetails />} />
      <Route path="/visit-store/:store_uuid" element={<StoreProfile />} />
      <Route
        path="/visit-store/:store_uuid/:encodedParams"
        element={<StoreProfile />}
      />
      <Route
        path="/visit-store/:store_uuid/:video_id"
        element={<StoreProfile />}
      />
      <Route path="/stores" element={<StoreListing />} />
      <Route path="/deals" element={<SeashelsEdits />} />
      <Route path="/shop" element={<Shop />} />
      <Route
        path="/shop/:category_id/:gender_id/:segment_id"
        element={<Shop />}
      />
      <Route
        path="/shop/:category_id/:gender_id/:segment_id/:sub_category_id"
        element={<Shop />}
      />
      <Route path="/weddingStoremain" element={<WeddingStoreMain />} />

      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/termsofservices" element={<TermsOfServeices />} />
      <Route
        path="/how-to-delete-your-account"
        element={<DeleteYourAccount />}
      />

      <Route
        path="/myorder"
        element={
          <ProtectedRoute>
            <MyOrder />
          </ProtectedRoute>
        }
      />
      <Route
        path="/my-favorites"
        element={
          <ProtectedRoute>
            <MyProfileFavorites />
          </ProtectedRoute>
        }
      />
      <Route path="/order/:orderId" element={<OrderDetails />} />
      <Route
        path="/my-order"
        element={
          <ProtectedRoute>
            <UserOrder />
          </ProtectedRoute>
        }
      />
      <Route
        path="/my-gift"
        element={
          <ProtectedRoute>
            <Gift />
          </ProtectedRoute>
        }
      />
      <Route
        path="/my-profile"
        element={
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        }
      />
      <Route
        path="/productlist"
        element={
          <ProtectedRoute>
            <ProductList />
          </ProtectedRoute>
        }
      />
      <Route
        path="/categorylist"
        element={
          <ProtectedRoute>
            <CategoryList />
          </ProtectedRoute>
        }
      />
      <Route
        path="/my-account"
        element={
          <ProtectedRoute>
            <Account />
          </ProtectedRoute>
        }
      />

      <Route path="/home" element={<HomePage />} />
      <Route path="/" element={<HomePage />} />

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default AppRoutes;
