import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  useMemo,
} from "react";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import api from "../../api";
import LoadMore from "../LoadMore/LoadMore";
import { useAuth } from "../../context/AuthContext";
import SortSelect from "../multiSelect/SortSelect";
import LazyLoadImage from "../LazyLoadImage";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { isMobile } from "react-device-detect";

const ITEMS_PER_PAGE = 54;

const LatestArrivalsMain = ({
  apiEndpoint = "/tags/get_sku_list_for_tag",
  pageTitle = "",
}) => {
  const location = useLocation();
  const hasUpdated = useRef(false);
  const { logout } = useAuth();
  const { tag_id, tag_type } = useParams();

  const [products, setProducts] = useState([]);
  const [originalProducts, setOriginalProducts] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadedItemCount, setLoadedItemCount] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [sortOrder, setSortOrder] = useState("");
  const [nextPage, setNextPage] = useState(null);
  const [prevPage, setPrevPage] = useState(null);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const navigate = useNavigate();
  const [selectedSort, setSelectedSort] = useState("");
  const [filterOptions, setFilterOptions] = useState({
    sortOptions: [
      { value: "low", label: "Price Low To High" },
      { value: "high", label: "Price High To Low" },
    ],
  });

  const fetchProducts = async (
    page = localStorage.getItem("cpnumber") || 0,
    append = false,
    currentSortOrder = ""
  ) => {
    try {
      setLoading(true);
      setLoadingMore(append);
      const currency_code = localStorage.getItem("selectedCurrency") || "2";

      const api_token = localStorage.getItem("apiToken");
      const response = await api.get(apiEndpoint, {
        params: {
          api_token,
          tag_type,
          tag_id,
          page: page,
          per_page: ITEMS_PER_PAGE,
          sort: currentSortOrder,
          currency_id: currency_code,
        },
      });

      if (response.data.status === 401) {
        logout();
        navigate("/home");
      } else if (response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = response.data;
      const processedProducts = data.tags_data;

      if (processedProducts.length === 0) {
        setNextPage(null);
        setPrevPage(data.prev_page);
        return;
      }

      const productsWithPageCount = processedProducts.map((product) => ({
        ...product,
        tags_page_count: data.tags_page_count,
      }));

      setProducts((prevProducts) =>
        append
          ? [...prevProducts, ...productsWithPageCount]
          : productsWithPageCount
      );

      setLoadedItemCount(processedProducts.length);
      setTotalItems(data.tags_items_total_count);
      setCurrentPageNumber(data.current_page);

      setNextPage(data.next_page);
      setPrevPage(data.prev_page);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
  };

  const handleSortChange = (selectedValue) => {
    setSortOrder(selectedValue);
    setSelectedSort(selectedValue);
    setLoadedItemCount(0);
    setProducts([]);
    fetchProducts(0, false, selectedValue);
  };

  const loadMoreProducts = () => {
    if (nextPage !== null) {
      fetchProducts(nextPage, true, sortOrder);
    }
  };

  const loadPreviousPage = () => {
    if (prevPage !== null) {
      fetchProducts(prevPage, false, sortOrder);
    }
  };

  useEffect(() => {
    const pageNumber = localStorage.getItem("cpnumber") || 0;
    fetchProducts(pageNumber, false, sortOrder);

    const savedPosition = localStorage.getItem("scrollPosition");

    if (savedPosition) {
      window.scrollTo(0, parseInt(savedPosition, 10));
    }
  }, [sortOrder, tag_id, tag_type]);

  const handleProductClick = (sui, cp) => {
    localStorage.setItem("scrollPosition", window.scrollY);
    localStorage.setItem("cpnumber", cp);
    localStorage.setItem("targetedProductId", sui);
    navigate(`/product/${sui}`);
  };

  useLayoutEffect(() => {
    const targetedProductId = localStorage.getItem("targetedProductId");

    if (targetedProductId && !hasUpdated.current) {
      const urlParams = new URLSearchParams(location.search);

      if (urlParams.get("tpid") !== targetedProductId) {
        urlParams.set("tpid", targetedProductId);
        navigate(`${location.pathname}?${urlParams.toString()}`, {
          replace: true,
        });

        hasUpdated.current = true;
      }
    }
  }, [navigate, location]);

  const handleClearFilter = (e) => {
    e.preventDefault();
    setSortOrder("");
    setProducts(originalProducts);
    fetchProducts(0, false, "");
    setSelectedSort([]);
  };

  const startItem =
    loadedItemCount > 0 ? (nextPage - 1) * ITEMS_PER_PAGE + 1 : 0;
  const endItem =
    loadedItemCount > 0
      ? Math.min(startItem + loadedItemCount - 1, totalItems)
      : 0;

  const showLoadMoreButton =
    loadedItemCount < totalItems && loadedItemCount % ITEMS_PER_PAGE === 0;

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div className="main-content">
      <div className="wear-offer latest-arrival">
        <div className="container">
          <h2>{pageTitle}</h2>

          <div className="sort-row">
            <div className="sort-by">
              <SortSelect
                onChange={(value) => handleSortChange(value)}
                data={filterOptions.sortOptions}
                selectedValue={selectedSort}
              />
            </div>
            <div className="clear">
              <a href="#" onClick={handleClearFilter}>
                CLEAR
              </a>
            </div>
          </div>
          {products?.length > 0 && (
            <div
              className="load-preview-button"
              style={{ textAlign: "center", marginBottom: "0px" }}
            >
              <button
                onClick={loadPreviousPage}
                disabled={prevPage === -1}
                className="load-more-button"
                style={{
                  display: prevPage === -1 ? "none" : "inline-block",
                }}
              >
                View Previous
              </button>
            </div>
          )}
          {/* <button onClick={loadPreviousPage} disabled={prevPage === -1}>
            Previous
          </button> */}
          <ProductListing
            products={products}
            loading={loading}
            loadedItemCount={loadedItemCount}
            ITEMS_PER_PAGE={ITEMS_PER_PAGE}
            handleProductClick={handleProductClick}
            sortOrder={sortOrder}
          />

          {loadedItemCount > 0 && (
            <div className="container_j8XTO">
              <p className="label_Ph1fi">
                You've viewed {endItem} of {totalItems} products
              </p>
              <progress
                max={totalItems}
                value={loadedItemCount}
                className="progressBar_anIzC"
                aria-hidden="true"
              ></progress>
            </div>
          )}

          <div className="pagination-controls">
            {showLoadMoreButton && (
              <div className="load-more-container">
                {!loadingMore ? (
                  <button
                    onClick={loadMoreProducts}
                    disabled={loadedItemCount >= totalItems}
                    className="load-more-button"
                  >
                    Load More
                  </button>
                ) : (
                  <div className="loading-spinner">
                    <img src="/images/circle-1700_256.gif" alt="Loading..." />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const ProductListing = ({
  products,
  loading,
  loadedItemCount,
  ITEMS_PER_PAGE,
  handleProductClick,
  sortOrder,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isScroll, setIsScroll] = useState(false);
  const heights = isMobile ? 320 : 400;
  const widths = isMobile ? 170 : 300;
  const productRefs = useRef({});

  const sortedProducts = useMemo(() => {
    if (sortOrder === "low") {
      return [...products].sort((a, b) => a.price - b.price);
    } else if (sortOrder === "high") {
      return [...products].sort((a, b) => b.price - a.price);
    }
    return products;
  }, [products, sortOrder]);

  const targetedProductId = localStorage.getItem("targetedProductId");

  const params = new URLSearchParams(window.location.search);
  const productIdToScroll =
    sessionStorage.getItem("targetedProductId") || params.get("tpid");

  useLayoutEffect(() => {
    setTimeout(() => {
      if (productIdToScroll && productRefs.current[productIdToScroll]) {
        productRefs.current[productIdToScroll].scrollIntoView({
          behavior: "instant",
          block: "center",
        });
      }
      setIsScroll(true);
    }, 1500);
  }, [productIdToScroll]);

  return (
    <div className="also-like latest">
      <ul
        style={{
          gridTemplateColumns: isMobile ? "1fr 1fr" : "",
        }}
      >
        {loading || loadedItemCount === 0 ? (
          [...Array(ITEMS_PER_PAGE)].map((_, index) => (
            <li key={`skeleton-${index}`} className="skeleton-loader1">
              <Link to="#" className="img-box">
                <Skeleton
                  width={widths}
                  height={heights}
                  baseColor="#ffffff"
                  highlightColor="#f8f8f8"
                  enableAnimation={false}
                />
              </Link>
              <div
                className="description content"
                style={{
                  height: "50px",
                  display: "flex",
                  flexDirection: "column",
                }}
              ></div>
            </li>
          ))
        ) : sortedProducts.length > 0 ? (
          sortedProducts.map((product) => (
            <li
              ref={(el) =>
                (productRefs.current[product.sui || targetedProductId] = el)
              }
              id={
                parseInt(product.sui, 10) === parseInt(targetedProductId, 10)
                  ? targetedProductId
                  : ""
              }
              key={product.sui}
              className={
                product.sui === targetedProductId
                  ? "highlight-targeted-product"
                  : ""
              }
              onClick={() =>
                handleProductClick(product.sui, product.tags_page_count)
              }
              style={{
                minHeight: heights,
                minWidth: widths,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",

                backgroundColor: "#fff",
              }}
            >
              <Link to="#" className="img-box" style={{ minHeight: heights }}>
                <LazyLoadImage
                  thumbnailSrc={product.image_url_thumb}
                  fullImageSrc={product.image_url}
                  alt={product.alt}
                />
                {(product.inStock === 0 || product.inStock === "0") && (
                  <span className="sold-out">Sold Out</span>
                )}
              </Link>
              <h4>{product.store_name}</h4>
              <div className="description content">
                <h4>
                  <Link to={`/product/${product.sui}`}>
                    {product.product_title}
                  </Link>
                </h4>
                {product.in_stock === 0 || !product.in_stock ? (
                  <span className="sold-out">Sold Out</span>
                ) : (
                  <div className="price-value">
                    {product.display_total_price !==
                    product.display_final_price ? (
                      <>
                        <span className="strike_price">
                          {`${product.display_currency_symbol}${product.display_total_price}`}
                        </span>
                        <span className="final_price">
                          {`${product.display_currency_symbol}${product.display_final_price}`}
                        </span>
                        <span className="discount">
                          {`${
                            product.display_discount ||
                            product.platform_discount
                          }% off`}
                        </span>
                      </>
                    ) : (
                      <span className="final_price">
                        {`${product.display_currency_symbol}${product.display_final_price}`}
                      </span>
                    )}
                  </div>
                )}
              </div>
            </li>
          ))
        ) : (
          <div>No products available</div>
        )}
      </ul>
    </div>
  );
};

export default LatestArrivalsMain;
