import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import OwlCarousel from "react-owl-carousel";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./VisitStoreDigitally.css";
import LazyLoadImage from "../LazyLoadImage";
import { isMobile } from "react-device-detect";
const VisitStoreDigitally = ({
  digital_stores = [],
  heading = "",
  loading,
}) => {
  const navigate = useNavigate();

  const settings = {
    items: 1,
    margin: 10,
    loop: digital_stores.length > 1,
    autoplay: digital_stores.length > 1,
    autoplayTimeout: 6000,
    dots: true,
    nav: false,
  };

  const handleBannerClick1 = (type_id, tag_type) => {
    navigate(`/latest-arrivals/${type_id}/${tag_type}`);
  };

  return (
    <section className="store">
      <div className="visit-store-digitally">
        <h2>
          {loading ? <Skeleton width={200} /> : heading}
          {!loading && <Link to="/stores" className="read-more"></Link>}
        </h2>

        <div className="carousel-section">
          {loading ? (
            <Skeleton height={630} count={1} enableAnimation={false} />
          ) : digital_stores.length > 0 ? (
            <OwlCarousel
              className="store-carousel owl-carousel owl-theme"
              {...settings}
            >
              {digital_stores.map((banner, index) => {
                const thumbnailSrc = isMobile
                  ? banner.media_url_web_mobile_thumb
                  : banner.media_url_web_desk_thumb;

                const fullImageSrc = isMobile
                  ? banner.media_url_web_mobile
                  : banner.media_url_web_desk;
                return (
                  <div
                    key={index + banner.type_id}
                    className="item"
                    onClick={() =>
                      handleBannerClick1(banner.type_id, banner.type)
                    }
                  >
                    <LazyLoadImage
                      baseColor="#ffffff"
                      highlightColor="#f8f8f8"
                      enableAnimation={false}
                      thumbnailSrc={thumbnailSrc}
                      fullImageSrc={fullImageSrc}
                      alt={"Store Image"}
                      className="carousel-image"
                    />
                  </div>
                );
              })}
            </OwlCarousel>
          ) : (
            <p>No digital stores available.</p>
          )}
        </div>
      </div>
    </section>
  );
};

export default VisitStoreDigitally;
