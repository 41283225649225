import React, { useState, useEffect, useCallback } from "react";
import Skeleton from "react-loading-skeleton";
import { Link, useNavigate, useParams } from "react-router-dom";

import api from "../../api";
import { useAuth } from "../../context/AuthContext";
import StoreTabFilter from "./StoreTabFilter";
import StoreFilterProductListing from "./StoreFilterProductListing";
import StoreFilterProducts from "./StoreFilterProducts";
import _ from "lodash";
import { isMobile } from "react-device-detect";

const StoreFilter = ({ storeUuid, genderList, loading, onTabSelection }) => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [tab, setTab] = useState("all");
  const [showAdditionalFilters, setShowAdditionalFilters] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const [filterOptions, setFilterOptions] = useState({
    sizeOptions: [],
    priceOptions: [],
    colorOptions: [],
    sortOptions: [
      { value: "ASC", label: "Price Low To High" },
      { value: "DESC", label: "Price High To Low" },
    ],
  });

  const [sortOption, setSortOption] = useState("");
  const [sizeOption, setSizeOption] = useState("");
  const [priceOption, setPriceOption] = useState("");
  const [colorOption, setColorOption] = useState("");
  const [segmentId, setSegmentId] = useState("");
  const [currencyCode, setcurrencyCode] = useState("");
  const [genderId, setGenderId] = useState("");
  const [categoryList, setCategoryList] = useState("");
  const [productData, setProductData] = useState(null);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [apiCallType, setApiCallType] = useState("");
  const [activeCategoryId, setActiveCategoryId] = useState(null);
  const hasWomen = genderList?.Women && genderList.Women.length > 0;
  const hasMen = genderList?.Men && genderList.Men.length > 0;
  const hasUnisex = genderList?.Unisex && genderList.Unisex.length > 0;
  const [initialLoad, setInitialLoad] = useState(true);
  const { store_uuid, encodedParams } = useParams();

  const decodedParams = decodeURIComponent(encodedParams);
  const ids = decodedParams?.split("-");
  useEffect(() => {
    if (ids[0] && ids[1] && ids[2]) {
      setActiveCategoryId(ids[2]);
      setSegmentId(ids[0]);
      setGenderId(ids[1]);
      fetchProductDataForItem(store_uuid, ids[0], ids[1], ids[2]);
    }
  }, []);

  useEffect(() => {
    if (hasWomen) {
      setActiveTab("tab1");
    } else if (hasMen) {
      setActiveTab("tab2");
    } else if (hasUnisex) {
      setActiveTab("tab3");
    }
  }, [hasWomen, hasMen, hasUnisex]);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    setShowAdditionalFilters(false);
    onTabSelection(tabId);
  };

  const fetchProductDataForItem = async (
    store_uuid,
    segment_id,
    gender_id,
    category_list
  ) => {
    setIsLoadingData(true);
    try {
      const api_token = localStorage.getItem("apiToken");
      const currencyCode = localStorage.getItem("currencyCode");

      const response = await api.get("/filters/get_skus_for_filter", {
        params: {
          api_token,
          page: 0,
          store_uuid,
          segment_id,
          gender_id,
          category_list,
          currency_code: currencyCode,
          sort_order: "",
          size_list: "",
          color_list: "",
          price_min: "",
          price_max: "",
          sub_category_list: "",
        },
      });

      if (response.status === 200) {
        setProductData(response.data);
        const PriceListResponse_price_band = response.data.price_band;
        const colorListResponse = await api.get("/filters/get_colors_list", {
          params: {
            api_token,
            gender_id,
            segment_id,
            categories_list: category_list,
            store_uuid,
            price_min: "",
            price_max: "",
            sub_categories_list: "",
          },
        });
        const colorListResponse_colors_data =
          colorListResponse.data.colors_data;
        const sizeListResponse = await api.get("/filters/get_sizes_list", {
          params: {
            api_token,
            gender_id,
            segment_id,
            categories_list: category_list,
            store_uuid,
            price_min: "",
            price_max: "",
            sub_categories_list: "",
          },
        });
        const sizeListResponse_sizes_data = sizeListResponse.data.sizes_data;

        setFilterOptions({
          ...filterOptions,
          sizeOptions: sizeListResponse_sizes_data,
          priceOptions: PriceListResponse_price_band,
          colorOptions: colorListResponse_colors_data,
        });

        setShowAdditionalFilters(true);
        setSegmentId(segment_id);
        setGenderId(gender_id);
        setCategoryList(category_list);
        setcurrencyCode(currencyCode);
      } else if (response.status === 401) {
        logout();
        navigate("/home");
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
    } finally {
      setIsLoadingData(false);
    }
  };

  const fetchFilteredData = async () => {
    setIsLoadingData(false);
    const formattedSize =
      sizeOption && sizeOption.length > 0 ? sizeOption.join(",") : "";
    const formattedColor =
      colorOption && colorOption.length > 0 ? colorOption.join(",") : "";

    try {
      const api_token = localStorage.getItem("apiToken");
      const currencyCode = localStorage.getItem("currencyCode");

      const response = await api.get("/filters/get_skus_for_filter", {
        params: {
          api_token,
          store_uuid: storeUuid,
          segment_id: segmentId,
          gender_id: genderId,
          category_list: categoryList,
          sub_category_list: "",
          size_list: formattedSize,
          color_list: formattedColor,
          price_min: priceOption.split("-")[0] || "",
          price_max: priceOption.split("-")[1] || "",
          sort_order: sortOption,
          page: 0,
          currency_code: currencyCode,
        },
      });

      if (response.status === 200) {
        setProductData(response.data);
        const PriceListResponse_price_band = response.data.price_band;
        const colorListResponse = await api.get("/filters/get_colors_list", {
          params: {
            api_token,
            gender_id: genderId,
            segment_id: segmentId,
            categories_list: categoryList,
            store_uuid: storeUuid,
            price_min: priceOption.split("-")[0] || "",
            price_max: priceOption.split("-")[1] || "",
            sub_categories_list: "",
          },
        });
        const colorListResponse_colors_data =
          colorListResponse.data.colors_data;
        const sizeListResponse = await api.get("/filters/get_sizes_list", {
          params: {
            api_token,
            gender_id: genderId,
            segment_id: segmentId,
            categories_list: categoryList,
            store_uuid: storeUuid,
            price_min: priceOption.split("-")[0] || "",
            price_max: priceOption.split("-")[1] || "",
            sub_categories_list: "",
          },
        });
        const sizeListResponse_sizes_data = sizeListResponse.data.sizes_data;

        setFilterOptions({
          ...filterOptions,
          sizeOptions: sizeListResponse_sizes_data,
          priceOptions: PriceListResponse_price_band,
          colorOptions: colorListResponse_colors_data,
        });

        setShowAdditionalFilters(true);
        setSegmentId(segmentId);
        setGenderId(genderId);
        setCategoryList(categoryList);
        setcurrencyCode(currencyCode);
      } else if (response.status === 401) {
        logout();
        navigate("/home");
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
    } finally {
      setIsLoadingData(false);
    }
  };

  const handleItemClick = (
    store_uuid,
    segment_id,
    gender_id,
    category_list
  ) => {
    const id = store_uuid;
    const param1 = segment_id;
    const param2 = gender_id;
    const param3 = category_list;
    const combinedParams = `${param1}-${param2}-${param3}`;

    const encodedParams = encodeURIComponent(combinedParams);
    navigate(`/visit-store/${id}/${encodedParams}`);

    setActiveCategoryId(category_list);
    setApiCallType("item");
    fetchProductDataForItem(store_uuid, segment_id, gender_id, category_list);
  };

  const handleFilterChange = useCallback(() => {
    setApiCallType("filter");
    fetchFilteredData();
  }, [sortOption, sizeOption, priceOption, colorOption]);

  const debouncedHandleFilterChange = useCallback(
    _.debounce(handleFilterChange, 300),
    [sortOption, sizeOption, priceOption, colorOption]
  );

  useEffect(() => {
    if (segmentId && genderId && categoryList) {
      debouncedHandleFilterChange();
    }
  }, [
    sortOption,
    sizeOption,
    priceOption,
    colorOption,
    debouncedHandleFilterChange,
  ]);

  const handleSortChange = (e) => {
    setSortOption(e);
  };

  const handleSizeChange = (e) => {
    setSizeOption(e);
  };

  const handlePriceChange = (e) => {
    setPriceOption(e);
  };

  const handleColorChange = (e) => {
    setColorOption(e);
  };

  const handleClearFilters = () => {
    setSizeOption("");
    setSortOption("");
    setPriceOption("");
    setColorOption("");
    setShowAdditionalFilters(false);
    setSegmentId("");
    setGenderId("");
    // setCategoryList("");
    setProductData(null);
  };

  const handlePriceClearFilter = () => {
    setPriceOption("");
    setShowAdditionalFilters(true);
  };
  const handleSizeClearFilter = () => {
    setSizeOption([]);
    setShowAdditionalFilters(true);
  };
  const handleColorClearFilter = () => {
    setColorOption([]);
    setShowAdditionalFilters(true);
  };
  return (
    <div className="store-filter">
      <div className="container">
        {isLoadingData || loading ? (
          <>
            <div className="tabs">
              <ul id="tabs-nav">
                <li>
                  <Skeleton height={30} width={100} />
                </li>
              </ul>
              <div id="tabs-content">
                <div id="tab1" className="tab-content">
                  <ul className="women-list">
                    {[...Array(5)].map((_, index) => (
                      <li key={index}>
                        <a href="#" className="category-item">
                          <Skeleton
                            height={75}
                            width={50}
                            style={{ borderRadius: "50%" }}
                          />{" "}
                          <Skeleton
                            height={20}
                            width={100}
                            style={{ marginTop: "10px" }}
                          />{" "}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="tabs">
              <ul id="tabs-nav">
                {hasWomen && (
                  <li className={activeTab === "tab1" ? "active" : ""}>
                    <a href="#tab1" onClick={() => handleTabClick("tab1")}>
                      Women
                    </a>
                  </li>
                )}
                {hasMen && (
                  <li className={activeTab === "tab2" ? "active" : ""}>
                    <a href="#tab2" onClick={() => handleTabClick("tab2")}>
                      Men
                    </a>
                  </li>
                )}
                {hasUnisex && (
                  <li className={activeTab === "tab3" ? "active" : ""}>
                    <a href="#tab3" onClick={() => handleTabClick("tab3")}>
                      Unisex
                    </a>
                  </li>
                )}
              </ul>
              <div id="tabs-content">
                {activeTab === "tab1" && hasWomen && (
                  <div id="tab1" className="tab-content">
                    <ul className="women-list">
                      {genderList.Women.map((item) => (
                        <li key={item.category_id}>
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              handleItemClick(
                                storeUuid,
                                item.segment_id,
                                item.gender_id,
                                item.category_id
                              );
                            }}
                            className={`category-item ${
                              activeCategoryId == item.category_id
                                ? "active_category"
                                : ""
                            }`}
                          >
                            <img src={item.image_url} alt={item.name} />
                            <span>{item.name}</span>
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {activeTab === "tab2" && hasMen && (
                  <div id="tab2" className="tab-content">
                    <ul className="men-list">
                      {genderList.Men.map((item) => (
                        <li key={item.category_id}>
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              handleItemClick(
                                storeUuid,
                                item.segment_id,
                                item.gender_id,
                                item.category_id
                              );
                            }}
                            className={`category-item ${
                              activeCategoryId === item.category_id
                                ? "active_category"
                                : ""
                            }`}
                          >
                            <img src={item.image_url} alt={item.name} />
                            <span>{item.name}</span>
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {activeTab === "tab3" && hasUnisex && (
                  <div id="tab3" className="tab-content">
                    <ul className="unisex-list">
                      {genderList.Unisex.map((item) => (
                        <li key={item.category_id}>
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              handleItemClick(
                                storeUuid,
                                item.segment_id,
                                item.gender_id,
                                item.category_id
                              );
                            }}
                            className={`category-item ${
                              activeCategoryId === item.category_id
                                ? "active_category"
                                : ""
                            }`}
                          >
                            <img src={item.image_url} alt={item.name} />
                            <span>{item.name}</span>
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>

            <StoreTabFilter
              sortOptions={filterOptions.sortOptions}
              sizeOptions={filterOptions.sizeOptions}
              priceOptions={filterOptions.priceOptions}
              colorOptions={filterOptions.colorOptions}
              onSortChange={handleSortChange}
              onSizeChange={handleSizeChange}
              onPriceChange={handlePriceChange}
              onColorChange={handleColorChange}
              onClearFilters={handleClearFilters}
              onPriceClearFilter={handlePriceClearFilter}
              onSizeClearFilter={handleSizeClearFilter}
              onColorClearFilter={handleColorClearFilter}
              sortOption={sortOption}
              sizeOption={sizeOption}
              priceOption={priceOption}
              colorOption={colorOption}
              loading={loading}
              showAdditionalFilters={showAdditionalFilters}
            />

            <div id="filter-products">
              <div className="container">
                {loading ? (
                  <div id="filter-products">
                    <div className="container">
                      <div className="also-like latest">
                        <ul className="skeleton-list">
                          {Array.from({ length: 16 }).map((_, index) => (
                            <li key={index}>
                              <Skeleton
                                height={isMobile ? 295 : 430}
                                width={isMobile ? 200 : 275}
                              />
                              <h4>
                                <Skeleton width={200} />
                              </h4>
                              <div className="price-value">
                                <Skeleton width={100} />
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                ) : productData ? (
                  <StoreFilterProductListing
                    storeUuid={storeUuid}
                    sortOption={sortOption}
                    sizeOption={sizeOption}
                    priceOption={priceOption}
                    colorOption={colorOption}
                    segmentId={segmentId}
                    genderId={genderId}
                    categoryList={categoryList}
                    productData={productData}
                    onClearFilters={handleClearFilters}
                    loading={loading}
                  />
                ) : (
                  <StoreFilterProducts
                    storeUuid={storeUuid}
                    loading={loading}
                    onClearFilters={handleClearFilters}
                    sortOption={sortOption}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default StoreFilter;
