import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import $ from "jquery";

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    $("html, body").scrollTop(0);
    $("#root").scrollTop(0);

    $(".main-content").scrollTop(0);
  }, [location.pathname, location.search]);

  return null;
};

export default ScrollToTop;
