const shareVideosLink = async (
  store_name,
  description,
  video_id,
  store_uuid,
  imagedata
) => {
  // Default image if no image data is provided
  const image = imagedata || "https://fallbackimageurl.com/default.jpg";

  // Retrieve user details from local storage
  const currentType = localStorage.getItem("userRole") || "guest";
  const country_code = localStorage.getItem("userCountryCode") || "US";

  // Get current hostname and port
  const hostname = window.location.hostname;
  const port = window.location.port;

  // Domain and port-specific defaults
  const domainDefaults = {
    "fashionxglobal.com": {
      title: "FashionX",
      description: "Style without borders",
    },
    "modestrend.com": {
      title: "Modestrend",
      description: "Modesty with Style.",
    },
    "asianethnics.com": {
      title: "Asian Ethnics",
      description: "Discover Global Asian Ethnics Style.",
    },
    3001: {
      title: "FashionX",
      description: "Style without borders",
    },
    3002: {
      title: "Modestrend",
      description: "Modesty with Style",
    },
    3003: {
      title: "Asian Ethnics",
      description: "Discover Global Asian Ethnics Style",
    },
    default: {
      title: "Modestrend",
      description: "Your fashion destination.",
    },
  };

  // Apply defaults based on hostname and port
  const defaults =
    domainDefaults[hostname] ||
    domainDefaults[port] ||
    domainDefaults["default"];

  const dynamicTitle = defaults.title;
  const dynamicDescription = description || defaults.description;

  // Firebase Dynamic Link API Key
  const API_KEY = "AIzaSyBmAr5tphOF7t3V48143Htw0G535wOP5xM";
  const url = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${API_KEY}`;

  const dynamicLinkConfig = {
    dynamicLinkInfo: {
      link: `https://connect.seashels.com/api/v1.0/Link/brodcast?id=${video_id}&store_id=${store_uuid}&brodcasttype=video`, // Minimized link parameters
      domainUriPrefix: "https://discover.seashels.com",
      androidInfo: {
        androidPackageName: "com.seashels.discover",
        androidFallbackLink: "https://connect.seashels.com",
        androidMinPackageVersionCode: "1",
      },
      iosInfo: {
        iosBundleId: "com.seashels.discover",
        iosFallbackLink: "https://connect.seashels.com",
        iosAppStoreId: "6463077820",
        iosMinimumVersion: "1.0",
      },
      socialMetaTagInfo: {
        socialTitle: dynamicTitle,
        socialDescription: dynamicDescription,
        socialImageLink: image,
      },
    },
    suffix: {
      option: "SHORT",
    },
  };

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dynamicLinkConfig),
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      console.error("Error response:", errorResponse);
      throw new Error(
        `Error creating dynamic link: ${errorResponse.error.message}`
      );
    }

    const result = await response.json();
    return result.shortLink;
  } catch (error) {
    console.error("Error creating dynamic link:", error);
  }
};

export default shareVideosLink;
