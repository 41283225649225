import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, useNavigate } from "react-router-dom";
import { AuthProvider, useAuth } from "./context/AuthContext";
import { CartProvider } from "./context/CartContext";
import { CurrencyProvider } from "./context/CurrencyContext";
import { CheckoutProvider } from "./context/CheckoutContext";
import ScrollToTop from "./components/scroll/ScrollToTop";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import AppRoutes from "./routes/Routes";
import { ToastNotification } from "./components/toastNotification/ToastNotification";
import "./App.css";
import { UtilsProvider } from "./context/UtilsContext";
import UserLocationProvider from "./context/userlocation/UserLocationProvider";
import { ClientInfoProvider } from "./context/ClientInfoContext";
const AppContent = () => {
  const cacheVersion = "1.13.11";
  console.log(cacheVersion);
  const { loading, apiToken, error } = useAuth();
  const [searchQuery, setSearchQuery] = useState("");
  useEffect(() => {
    const storedVersion = localStorage.getItem("appVersion");
    if (storedVersion !== cacheVersion) {
      localStorage.clear();
      localStorage.setItem("appVersion", cacheVersion);
      window.location.reload();
    }
  }, [cacheVersion]);

  if (loading || error) {
    return null;
  }
  if (!apiToken) {
    return null;
  }

  return (
    <>
      <Header />
      <main className="main-content">
        <ScrollToTop />
        <AppRoutes />
      </main>
      <Footer />
    </>
  );
};

const App = () => {
  return (
    <ClientInfoProvider>
      <AuthProvider>
        <UtilsProvider>
          <CurrencyProvider>
            <CartProvider>
              {/* <CheckoutProvider> */}
              <Router>
                <div className="app-container">
                  <AppContent />
                </div>
              </Router>
              {/* </CheckoutProvider> */}
            </CartProvider>
          </CurrencyProvider>
        </UtilsProvider>
      </AuthProvider>
    </ClientInfoProvider>
  );
};

export default App;
