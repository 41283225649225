import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useCurrency } from "../../context/CurrencyContext";
import { useAuth } from "../../context/AuthContext";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import api from "../../api";
import LazyLoadImage from "../LazyLoadImage";
import LoadMore from "../LoadMore/LoadMore";

const ITEMS_PER_PAGE = 54;

const MultiShopFilterProductListing = ({
  storeUuid,
  sortOption,
  sizeOption,
  priceOption,
  colorOption,
  segmentId,
  genderId,
  categoryList,
  sub_category_list,
  productData,
  onClearFilters,
  loading: propLoading,
}) => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const { selectedCurrency } = useCurrency();

  const [activeTab, setActiveTab] = useState("All");
  const [loading, setLoading] = useState(propLoading);
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(null);
  const [loadedItemCount, setLoadedItemCount] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);
  const [isSuiData, setIsSuiData] = useState(false);
  useEffect(() => {
    const saveScrollPosition = () => {
      localStorage.setItem("scrollY", window.scrollY);
    };

    window.addEventListener("beforeunload", saveScrollPosition);

    const savedScrollY = localStorage.getItem("scrollY");
    if (savedScrollY) {
      window.scrollTo(0, parseInt(savedScrollY, 10));
    }

    return () => {
      window.removeEventListener("beforeunload", saveScrollPosition);
    };
  }, []);
  const fetchProducts = async (append = false) => {
    try {
      if (!append) {
        setLoading(true);
        setLoadedItemCount(0);
      } else {
        setLoadingMore(true);
      }
      const api_token = localStorage.getItem("apiToken");
      const currencyCode = localStorage.getItem("currencyCode");

      const params = {
        api_token,
        page: Math.floor(loadedItemCount / ITEMS_PER_PAGE),
        size_list: sizeOption || "",
        color_list: colorOption.join(","),
        price_min: priceOption ? priceOption.split("-")[0] : "",
        price_max: priceOption ? priceOption.split("-")[1] : "",
        segment_id: segmentId || "",
        gender_id: genderId || "",
        category_list: categoryList.join(",") || "",
        currency_code: currencyCode || "",
        sort_order: sortOption || "",
        store_uuid: storeUuid || "",
        sub_category_list: sub_category_list.join(","),
      };

      const response = await api.get("/filters/get_skus_for_filter", {
        params,
      });
      if (response.status === 401) {
        logout();
        navigate("/home");
      } else if (response.status === 200) {
        const data = response.data;
        const processedProducts = data.sui_data || data.sku_data || [];
        const totalItems = data.total_count;

        if (!processedProducts.length) {
          setProducts([]);
          setLoadedItemCount(0);
          setTotalItems(0);
          setIsSuiData(false);
        } else {
          setProducts((prevProducts) =>
            append ? [...prevProducts, ...processedProducts] : processedProducts
          );

          const newLoadedCount = append
            ? loadedItemCount + processedProducts.length
            : processedProducts.length;
          setLoadedItemCount(newLoadedCount);
          setTotalItems(totalItems);
          setIsSuiData(!!data.sui_data);
        }
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      setError("Failed to fetch products", error);
    } finally {
      setLoading(true);
      setLoadingMore(false);
    }
  };
  useEffect(() => {
    if (!productData) {
      fetchProducts(false);
    } else {
      const productsData = productData.data || productData.sui_data || [];
      setProducts(productsData);
      setTotalItems(productData.total_count || 0);
      setLoadedItemCount(productsData.length || 0);
      setLoading(false);
    }
  }, [
    storeUuid,
    sortOption,
    sizeOption,
    priceOption,
    colorOption,
    segmentId,
    genderId,
    categoryList,
    productData,
  ]);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const handleProductClick = (productSui) => {
    navigate(`/product/${productSui}`);
  };

  const loadMoreProducts = () => {
    fetchProducts(true);
  };

  if (products.length === 0) {
    return <div className="no-products">No products available</div>;
  }

  return (
    <div className="also-like latest uniquelist">
      {products.length > 0 ? (
        <ul>
          {products?.map((product, index) => {
            const cacheBuster = new Date().getTime();
            const imageUrlWithCacheBuster = `${
              product.image_url || product.image_url_thumb
            }?cb=${cacheBuster}`;
            const SUI_data = isSuiData ? product.SUI : product.sui;
            const title = isSuiData ? product.product_title : product.sku_title;
            const total_price = isSuiData
              ? product.display_final_price
              : product.display_final_price;
            const strike_price = isSuiData
              ? product.display_total_price
              : product.display_total_price;
            const discount = isSuiData
              ? product.platform_discount
              : product.sku_discount;
            const currency_symbol = isSuiData
              ? product.display_currency_symbol
              : product.display_currency_symbol;

            return (
              <li key={`${index}`}>
                <Link
                  to={`/product/${product.SUI || product.sui}`}
                  className="img-box"
                >
                  <LazyLoadImage
                    key={product.image_url || product.image_url_thumb}
                    thumbnailSrc={product.image_url || product.image_url_thumb}
                    fullImageSrc={product.image_url || product.image_url_thumb}
                    alt={title || "Product image"}
                  />
                  {product.in_stock === 0 && (
                    <span className="sold-out">Sold Out</span>
                  )}
                </Link>
                <h4 className="store_name">{product.store_name}</h4>
                <div className="description content">
                  <h4>
                    <Link to={`/product/${product.SUI || product.sui}`}>
                      {product.product_title || product.sku_title}
                    </Link>
                  </h4>
                  <div className="price-value">
                    {strike_price && strike_price !== total_price && (
                      <>
                        <span className="strike_price">
                          {`${currency_symbol || "$"}${strike_price}`}
                        </span>
                        <span className="final_price">
                          {`${currency_symbol || "$"}${total_price}`}
                        </span>
                        <span className="discount">
                          {`${
                            product.platform_discount || product.sku_discount
                          }% off`}
                        </span>
                      </>
                    )}
                    {(!strike_price || strike_price === total_price) && (
                      <span className="final_price">
                        {`${currency_symbol || "$"}${total_price}`}
                      </span>
                    )}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      ) : (
        <div className="no-products">No products available</div>
      )}
      <LoadMore
        loadedItemCount={loadedItemCount}
        totalItems={totalItems}
        onLoadMore={loadMoreProducts}
        loadingMore={loadingMore}
      />
    </div>
  );
};

export default MultiShopFilterProductListing;
