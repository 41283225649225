import axios from "axios";

const api = axios.create({
  baseURL: "https://global.seashels.com:7777/api",
  // baseURL: "https://development.seashels.com:7777/api",
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export default api;
