import CountryCodeString from "../components/currencySelector/countryCodes.json";

export const convertPhoneNumber = (phoneNumber) => {
  const [country, number] = phoneNumber.split("-");
  const countryCode = CountryCodeString[country];

  if (countryCode) {
    return `${countryCode}-${number}`;
  } else {
    throw new Error("Invalid phone code");
  }
};

export const CountryCodeWithString = (phoneNumber) => {
  const [country] = phoneNumber.split("-");
  const countryCode = CountryCodeString[country];

  if (countryCode) {
    return `${countryCode}`;
  } else {
    throw new Error("Invalid country code");
  }
};
