import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./WeddingStore.css";
import LazyLoadImage from "../LazyLoadImage";
import { isMobile } from "react-device-detect";
const WeddingStore = ({ sliderData, loading }) => {
  const navigate = useNavigate();

  const handleBannerClick = (type_id, tag_type) => {
    navigate(`/latest-arrivals/${type_id}/${tag_type}`);
  };

  const settings = {
    items: 1,
    margin: 10,
    loop: true,
    autoplay: true,
    autoplayTimeout: 6000,
    dots: true,
    responsive: {
      0: { items: 1 },
      768: { items: 1 },
    },
  };
  const extractPlainText = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    return doc.body.textContent || "";
  };
  return (
    <section className="wedding">
      {loading ? (
        <OwlCarousel
          className="banner-carousel owl-carousel owl-theme"
          {...settings}
        >
          {[...Array(3)].map((_, index) => (
            <div key={index} className="item">
              <Link to="#" className="banner-btn">
                <Skeleton height={630} enableAnimation={false} />
              </Link>
            </div>
          ))}
        </OwlCarousel>
      ) : (
        <OwlCarousel
          className="banner-carousel owl-carousel owl-theme"
          {...settings}
        >
          {sliderData?.map((banner, index) => {
            const thumbnailSrc = isMobile
              ? banner.media_url_web_mobile_thumb
              : banner.media_url_web_desk_thumb;
            const fullImageSrc = isMobile
              ? banner.media_url_web_mobile
              : banner.media_url_web_desk;

            return (
              <div
                key={index}
                className="item"
                onClick={() => handleBannerClick(banner.type_id, banner.type)}
              >
                <Link to="#" className="banner-btn">
                  <LazyLoadImage
                    thumbnailSrc={thumbnailSrc}
                    fullImageSrc={fullImageSrc}
                    alt={
                      extractPlainText(banner.heading_1) ||
                      "Wedding Store Banner"
                    }
                  />
                </Link>
              </div>
            );
          })}
        </OwlCarousel>
      )}
    </section>
  );
};

export default WeddingStore;
