import React, {
  useMemo,
  useLayoutEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import LazyLoadImage from "../LazyLoadImage";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const ProductListing = ({
  products,
  loading,
  loadedItemCount,
  ITEMS_PER_PAGE,
  handleProductClick,
  sortOrder,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isScroll, setIsScroll] = useState(false);

  const productRefs = useRef({});

  const sortedProducts = useMemo(() => {
    if (sortOrder === "low") {
      return [...products].sort((a, b) => a.price - b.price);
    } else if (sortOrder === "high") {
      return [...products].sort((a, b) => b.price - a.price);
    }
    return products;
  }, [products, sortOrder]);

  const targetedProductId = localStorage.getItem("targetedProductId");

  const params = new URLSearchParams(window.location.search);
  const productIdToScroll =
    sessionStorage.getItem("targetedProductId") || params.get("tpid");

  useLayoutEffect(() => {
    const page = parseInt(params.get("page"));
    setIsScroll(false);
    const timer = setTimeout(() => {
      if (productIdToScroll && productRefs.current[productIdToScroll]) {
        productRefs.current[productIdToScroll].scrollIntoView();
      }

      setIsScroll(true);
    }, 400);

    return () => clearTimeout(timer);
  }, [productIdToScroll]);

  return (
    <div
      className="also-like latest"
      style={{ opacity: productIdToScroll ? (isScroll ? "1" : "0") : "1" }}
    >
      <ul>
        {loading && loadedItemCount === 0 ? (
          [...Array(ITEMS_PER_PAGE)].map((_, index) => (
            <li key={`skeleton-${index}`} className="skeleton-loader">
              <Link to="#" className="img-box">
                <Skeleton height={400} width={300} />
                <Skeleton width={100} />
              </Link>
              <div className="description content">
                <Skeleton width={150} />
                <Skeleton width={100} />
                <Skeleton width={80} />
              </div>
            </li>
          ))
        ) : sortedProducts.length > 0 ? (
          sortedProducts.map((product, index) => (
            <li
              key={`${product.id}-${index}`}
              id={product.id}
              ref={(el) => (productRefs.current[product.id] = el)}
              className={
                product.id === targetedProductId
                  ? "highlight-targeted-product"
                  : ""
              }
              onClick={() =>
                handleProductClick(product.sui, product.tags_page_count)
              }
            >
              <Link to="#" className="img-box">
                <LazyLoadImage
                  thumbnailSrc={product.image_url_thumb}
                  fullImageSrc={product.image_url}
                  alt={product.alt}
                />
                {(product.inStock === 0 || product.inStock === "0") && (
                  <span className="sold-out">Sold Out</span>
                )}
              </Link>
              <h4>{product.store_name}</h4>
              <div className="description content">
                <h4>
                  <Link to={`/product/${product.sui}`}>
                    {product.product_title}
                  </Link>
                </h4>
                {product.in_stock === 0 ||
                product.in_stock === "" ||
                product.in_stock === null ? (
                  <span className="sold-out">Sold Out</span>
                ) : (
                  <div className="price-value">
                    {product.display_total_price !==
                    product.display_final_price ? (
                      <>
                        <span className="strike_price">
                          {`${product.display_currency_symbol}${product.display_total_price}`}
                        </span>
                        <span className="final_price">
                          {`${product.display_currency_symbol}${product.display_final_price}`}
                        </span>
                        <span className="discount">
                          {`${
                            product.display_discount ||
                            product.platform_discount
                          }% off`}
                        </span>
                      </>
                    ) : (
                      <span className="final_price">
                        {`${product.display_currency_symbol}${product.display_final_price}`}
                      </span>
                    )}
                  </div>
                )}
              </div>
            </li>
          ))
        ) : (
          <p>No products available</p>
        )}
      </ul>
    </div>
  );
};

export default ProductListing;
