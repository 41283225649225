import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";

const SizeGuide = ({ sizeguide, sizeguideChart, sizeguideType }) => {
  const [selectedUnit, setSelectedUnit] = useState("cm");
  const [selectedChartType, setSelectedChartType] = useState("");
  const [sizeChartData, setSizeChartData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (sizeguide?.length > 0 && !selectedChartType) {
      setSelectedChartType(sizeguide[0]);
    }
  }, [sizeguide, selectedChartType]);

  useEffect(() => {
    const fetchSizeChartData = () => {
      const chartData = sizeguideChart?.find((chart) => chart[selectedUnit]);
      setSizeChartData(chartData ? chartData[selectedUnit] : []);
      setLoading(false);
    };

    if (selectedChartType) {
      fetchSizeChartData();
    }
  }, [selectedChartType, sizeguideChart, selectedUnit]);

  const handleUnitChange = (e) => {
    setSelectedUnit(e.target.checked ? "inch" : "cm");
    setLoading(true);
  };

  const handleChartTypeChange = (e) => {
    setSelectedChartType(e.target.value);
    setLoading(true);
  };

  const renderSizeData = loading ? (
    Array.from({ length: 3 }).map((_, index) => (
      <div className="chart-box" key={index}>
        <h3>
          <Skeleton width={80} />
        </h3>
        <ul>
          <li>
            <span className="title">
              <Skeleton width={60} />
            </span>
            <span className="size">
              <Skeleton width={40} />
            </span>
          </li>
          <li>
            <span className="title">
              <Skeleton width={60} />
            </span>
            <span className="size">
              <Skeleton width={40} />
            </span>
          </li>
          <li>
            <span className="title">
              <Skeleton width={60} />
            </span>
            <span className="size">
              <Skeleton width={40} />
            </span>
          </li>
          <li>
            <span className="title">
              <Skeleton width={60} />
            </span>
            <span className="size">
              <Skeleton width={40} />
            </span>
          </li>
        </ul>
      </div>
    ))
  ) : sizeChartData?.length === 0 ? (
    <div style={{ display: "none" }}>No size data available</div>
  ) : (
    sizeChartData?.map((item, index) => (
      <div className="chart-box" key={index}>
        <h3>
          {selectedChartType === "EU" && `${item.Size}-${item.EU}`}
          {selectedChartType === "UK" && `${item.Size}-${item.UK}`}
          {selectedChartType === "US" && `${item.Size}-${item.US}`}
          {!(
            selectedChartType === "EU" ||
            selectedChartType === "UK" ||
            selectedChartType === "US"
          ) && item.Size}
        </h3>
        <ul>
          <li>
            <span className="title">Bust</span>
            <span className="size">{item.Bust}</span>
          </li>
          <li>
            <span className="title">Waist</span>
            <span className="size">{item.Waist}</span>
          </li>
          <li>
            <span className="title">Hip</span>
            <span className="size">{item.Hip}</span>
          </li>
          <li>
            <span className="title">Inseam</span>
            <span className="size">{item.Inseam}</span>
          </li>
        </ul>
      </div>
    ))
  );

  return (
    <div className="size-guide" id="size-guide" style={{ display: "none" }}>
      <div className="size-head">
        <div className="left">
          <label htmlFor="cm">cm</label>
          <div className="switch">
            <input
              type="checkbox"
              id="switch"
              checked={selectedUnit === "inch"}
              onChange={handleUnitChange}
            />
            <label htmlFor="switch">Toggle</label>
          </div>
          <label htmlFor="inch">in</label>
        </div>
        <div className="right">
          <label htmlFor="">Size Chart</label>
          {sizeguide?.length === 0 ? (
            <Skeleton width={120} height={30} />
          ) : (
            <select value={selectedChartType} onChange={handleChartTypeChange}>
              {sizeguide?.map((chart_list, index) => (
                <option key={index} value={chart_list}>
                  {chart_list}
                </option>
              ))}
            </select>
          )}
        </div>
      </div>
      <div className="size-chart">
        <div className="chart-boxes">{renderSizeData}</div>
      </div>
    </div>
  );
};

export default SizeGuide;
