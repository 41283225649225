import React, { createContext, useState, useEffect } from "react";
import api from "../api";
import { useAuth } from "../context/AuthContext";

const CheckoutContext = createContext();

const CheckoutProvider = ({ children }) => {
  const { logout } = useAuth();

  const [existingAddress, setExistingAddress] = useState([]);
  const [currency, setCurrency] = useState(null);
  const [symbol, setSymbol] = useState("");
  const [remaining, setRemaining] = useState(0);
  const [couponType, setCouponType] = useState("");
  const [shippingData, setShippingData] = useState([]);
  const [shipObjectId, setShipObjectId] = useState("");
  const [shipLocalAmount, setShipLocalAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalIncludeShipping, setTotalIncludeShipping] = useState(0);
  const [showShipping, setShowShipping] = useState(false);

  const api_token = localStorage.getItem("apiToken");
  const cart_id = localStorage.getItem("cart_id");
  const order_num = localStorage.getItem("order_num");
  const isValidApiToken = () =>
    api_token &&
    api_token !== "null" &&
    api_token !== "undefined" &&
    api_token.trim() !== "";

  const getAddress = async () => {
    if (!isValidApiToken()) return;

    try {
      const response = await api.get(
        `/user/get_user_address_list?&api_token=${api_token}`
      );

      setExistingAddress(response.data);
      const data = response.data;

      if (data?.user_address?.length > 0) {
        const existing = data.user_address[0] || {};
        if (existing.address_id) {
          calculateCredit();
        }
      } else if (response.status === 401) {
        logout();
      }
    } catch (error) {}
  };

  const addAddress = async (data) => {
    if (!isValidApiToken()) return;

    const userid = localStorage.getItem("userUuid");
    const formattedData = {
      api_token: api_token,
      type: "home",

      name: `${data.name}, ${data.last_name}`,
      email: data.email,
      phone: data.phone,
      user_id: userid,
      address_1: data.address_1,
      address_2: data.address_2,
      city: data.city,
      state: data.state,
      country: data.country,
      country_code: data.country_code,
      zipcode: data.zipcode,
      ...(data.address_id ? { address_id: data.address_id } : {}),
    };

    try {
      const response = await api.post(`/user/add_address`, formattedData);

      getAddress();
    } catch (error) {}
  };

  const calculateCredit = async () => {
    if (!isValidApiToken() || !order_num) return;

    try {
      const response = await api.get(
        `/credits/calculate_credits?&api_token=${api_token}&order_num=${order_num}&coupon_id=0`
      );

      if (response.data.status === 200) {
        const calculateCreditsResponse = response.data;

        if (
          calculateCreditsResponse.currency_code &&
          calculateCreditsResponse.currency_code.length > 0
        ) {
          setCurrency(calculateCreditsResponse.currency_code);
          setSymbol(calculateCreditsResponse.currency_symbol);
        }

        if (
          calculateCreditsResponse.remaining &&
          calculateCreditsResponse.remaining.length > 0
        ) {
          setRemaining(calculateCreditsResponse.remaining);
        }

        if (
          calculateCreditsResponse.coupon_type &&
          calculateCreditsResponse.coupon_type.length > 0
        ) {
          setCouponType(calculateCreditsResponse.coupon_type);
        }

        if (
          calculateCreditsResponse?.total_shipping_cost &&
          calculateCreditsResponse?.total_shipping_cost?.length > 0
        ) {
          setShowShipping(true);
          setShippingData(calculateCreditsResponse.total_shipping_cost);
          setShipObjectId(calculateCreditsResponse.internal_ship_ref);
          setShipLocalAmount(
            parseFloat(calculateCreditsResponse.total_shipping_cost[0].cost)
          );
          setTotalAmount(
            parseInt(calculateCreditsResponse.total_cart_value, 10)
          );
        } else {
          setShowShipping(false);
          setTotalAmount(
            parseInt(calculateCreditsResponse.total_cart_value, 10)
          );
        }

        const totalShippingAmount =
          parseInt(calculateCreditsResponse.total_cart_value, 10) +
          parseFloat(shipLocalAmount);
        setTotalIncludeShipping(totalShippingAmount);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (isValidApiToken()) {
      getAddress();
    }
  }, [api_token]);

  return (
    <CheckoutContext.Provider
      value={{
        existingAddress,
        addAddress,
        calculateCredit,
        currency,
        symbol,
        remaining,
        couponType,
        shippingData,
        shipObjectId,
        shipLocalAmount,
        totalAmount,
        totalIncludeShipping,
        showShipping,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};

export { CheckoutContext, CheckoutProvider };
