// src/components/CategoryList.js

import React, { useState, useEffect } from "react";
import axiosInstance from "../api/axiosInstance";
import Loader from "./LoadMore/Loader"; // Import the Loader component
import ProductList from "./latestArrivals/ProductList";

const CategoryList = () => {
  const [categories, setCategories] = useState({}); // Change to object to match response structure
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedGender, setSelectedGender] = useState("Bride"); // Add a state to track selected gender
  const [loading, setLoading] = useState(true); // Add loading state
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch categories from API
    const fetchCategories = async () => {
      setLoading(true); // Set loading to true before API call
      try {
        const response = await axiosInstance.get(
          "/api/tags/get_list_for_tag?api_token=guest_271ca7aeabba4853881e94a9d9767ced&tag_type=product&tag_id=95&count=&page=0&store_uuid=&category_id="
        );
        setCategories(response.data.categories);
      } catch (err) {
        console.error(err);
        setError("Error fetching categories.");
      } finally {
        setLoading(false); // Set loading to false after API call
      }
    };

    fetchCategories();
  }, []);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const handleGenderSelect = (gender) => {
    setSelectedGender(gender);
    setSelectedCategory(null); // Reset the selected category when changing gender
  };

  if (loading) {
    return <Loader />; // Display the loader when loading
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="category-list">
      <h1>Categories</h1>

      {/* Gender Selection */}
      <div className="gender-selector">
        {Object.keys(categories).map((gender) => (
          <button
            key={gender}
            onClick={() => handleGenderSelect(gender)}
            className={gender === selectedGender ? "active" : ""}
          >
            {gender}
          </button>
        ))}
      </div>

      {/* Category List */}
      <ul>
        {categories[selectedGender] &&
          categories[selectedGender].map((category) => (
            <li key={category.category_id}>
              <button onClick={() => handleCategoryClick(category)}>
                {category.category_name}
              </button>
            </li>
          ))}
      </ul>

      {/* Product List for Selected Category */}
      {selectedCategory && <ProductList category={selectedCategory} />}
    </div>
  );
};

export default CategoryList;
