// src/context/CartContext.js
import React, { createContext, useState, useContext, useEffect } from "react";

const CartContext = createContext();

export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
  const [cartCount, setCartCount] = useState(() => {
    const savedCartCount = localStorage.getItem("cartCount");
    return savedCartCount ? parseInt(savedCartCount, 10) : 0;
  });

  const addItemToCart = () => {
    setCartCount((prevCount) => prevCount + 1);
  };

  const removeItemFromCart = () => {
    setCartCount((prevCount) => (prevCount > 0 ? prevCount - 1 : 0));
  };

  const clearCart = () => {
    setCartCount(0);
  };

  useEffect(() => {
    localStorage.setItem("cartCount", cartCount);
  }, [cartCount]);

  return (
    <CartContext.Provider
      value={{
        cartCount,
        addItemToCart,
        removeItemFromCart,
        clearCart,
        setCartCount,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
