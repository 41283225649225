import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import "./Filter.css";

import {
  fetchStoreData,
  clearShopState,
  selectGridClass,
  setScrollPosition,
} from "../../redux/slices/shopSlice";
import Breadcrumb from "../breadCrumb/Breadcrumb";
import ShopFilter from "./ShopFilter";
import MultiCategoryShopFilter from "./MultiCategoryShopFilter";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const Shop = ({
  apiEndpoint = "/filters/get_sub_categories_list",
  pageTitle = "",
}) => {
  const heights = isMobile ? 275 : 400;
  const widths = isMobile ? 170 : 300;
  const { category_id, gender_id, segment_id, sub_category_id } = useParams();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [showGridClass, setShowGridClass] = useState(false);

  const dispatch = useDispatch();
  const { storeData, loading, error } = useSelector((state) => state.shop);
  const gridClass = useSelector(selectGridClass);

  const categoryId = location.state?.categoryId || category_id;
  const subCategoryId = location.state?.subCategoryId || sub_category_id;
  if (location.state?.color_id && location.state?.color_id != null) {
    sessionStorage.removeItem("colorOption");
    sessionStorage.setItem(
      "colorOption",
      JSON.stringify([location.state?.color_id])
    );
  }
  const isCategoryIdArray = Array.isArray(categoryId) && categoryId.length > 0;
  const isSubCategoryIdArray =
    Array.isArray(subCategoryId) && subCategoryId.length > 0;

  useEffect(() => {
    const api_token = localStorage.getItem("apiToken");
    dispatch(
      fetchStoreData({
        apiEndpoint,
        params: {
          api_token,
          segment_id: segment_id || "",
          gender_id: gender_id || 2,
          category_id: categoryId || "",
          page: 0,
        },
      })
    );

    return () => {
      dispatch(clearShopState());
    };
  }, [apiEndpoint, segment_id, gender_id, categoryId, dispatch]);

  useEffect(() => {
    if (Array.isArray(storeData) && storeData.length === 0 && !isMobile) {
      setShowGridClass(true);
    }
  }, [storeData]);

  useEffect(() => {
    if (error === "Unauthorized") {
      logout();
      navigate("/home");
    }
  }, [error, logout, navigate]);

  const handleProductClick = (productId, page) => {
    const scrollPosition = document.documentElement.scrollTop;

    sessionStorage.setItem("scrollPosition", scrollPosition);
    sessionStorage.setItem("currentpagenumber", productId.page);
    sessionStorage.setItem("pid", productId.productId);

    if (productId.page > 0 && productId.page !== undefined) {
      sessionStorage.setItem("currentpagenumber", productId.page);
      sessionStorage.setItem("previouspagenumber", productId.page);
      sessionStorage.setItem("pid", productId.productId);
    }

    navigate(`/product/${productId.productId}`);
  };

  if (loading) {
    return (
      <div className="also-like latest uniquelist">
        <ul className="skeleton-list">
          {Array.from({ length: 8 }).map((_, index) => (
            <li key={index}>
              <Skeleton
                height={heights}
                width={widths}
                baseColor="#ffffff"
                highlightColor="#f8f8f8"
                enableAnimation={false}
              />
              <h4>
                <Skeleton
                  width={widths}
                  baseColor="#ffffff"
                  highlightColor="#f8f8f8"
                  enableAnimation={false}
                />
              </h4>
              <div className="price-value">
                <Skeleton
                  width={100}
                  baseColor="#ffffff"
                  highlightColor="#f8f8f8"
                  enableAnimation={false}
                />
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  return (
    <div className={`main-content not-home shop-section-data ${gridClass}`}>
      {isCategoryIdArray || isSubCategoryIdArray ? (
        <MultiCategoryShopFilter
          categoryIds={isCategoryIdArray ? categoryId : [category_id]}
          subCategoryIds={
            isSubCategoryIdArray ? subCategoryId : [sub_category_id]
          }
          SubCategoryList_data={storeData}
          isLoading={loading}
        />
      ) : (
        <>
          <Breadcrumb />
          <ShopFilter
            SubCategoryList_data={storeData}
            isLoading={loading}
            onProductClick={handleProductClick}
          />
        </>
      )}
    </div>
  );
};

export default Shop;
