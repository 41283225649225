import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./LiveSocial.css";
import Modal from "../modal/Modal";
import { isMobile } from "react-device-detect";

const LiveSocial = ({ live_videos, loading, style }) => {
  const [dimensions, setDimensions] = useState({
    width: "100%",
    height: isMobile ? "275px" : "450px",
  });
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [selectedProductLinks, setSelectedProductLinks] = useState([]);

  const openModal = (videoUrl, productLinks) => {
    const productLinksArray = Array.isArray(productLinks.product_links)
      ? productLinks.product_links
      : [productLinks.product_links];
    const transformedVideos = productLinksArray.map((link) => ({
      ...productLinks,
      product_sui: link,
    }));
    setSelectedVideo(videoUrl);
    setSelectedProductLinks(transformedVideos[0]);
  };

  const closeModal = () => {
    setSelectedVideo(null);
    setSelectedProductLinks([]);
  };

  return (
    <section className="live">
      <div className="container">
        {loading ? (
          <>
            <Skeleton
              width={150}
              height={40}
              style={{ marginBottom: "10px" }}
              enableAnimation={false}
            />
            <div
              className="social-carousel"
              style={{
                display: "flex",
                gap: "10px",
                overflow: "hidden",
              }}
            >
              {[...Array(6)].map((_, index) => (
                <div key={index} className="item">
                  <div className="img-box">
                    <Skeleton
                      height={350}
                      width={250}
                      enableAnimation={false}
                    />
                  </div>
                  <div className="content">
                    <Skeleton
                      width={150}
                      height={30}
                      style={{ marginTop: "10px" }}
                      enableAnimation={false}
                    />
                    <Skeleton width={150} height={40} enableAnimation={false} />
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
            <h2 className="read-more">{live_videos?.heading}</h2>
            <div
              className="social-carousel"
              style={{
                display: "flex",
                gap: "10px",
                overflow: "hidden",
              }}
            >
              {live_videos?.data?.carousel?.map((item, index) => (
                <div
                  key={index}
                  className="item"
                  style={{
                    width: "calc(100% / 4 - 10px)",
                    flexShrink: 0,
                  }}
                >
                  <div
                    className="img-box"
                    style={{
                      width: "100%",
                      aspectRatio: "16 / 9",
                      position: "relative",
                      overflow: "hidden",
                      cursor: "pointer",
                      backgroundColor: "#f0f0f0",
                    }}
                    onClick={() => openModal(item.video_url, item)}
                  >
                    <img
                      src={item.video_thumbnail_web}
                      alt={`Social Image ${index + 1}`}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                  <div
                    className="content"
                    style={{
                      marginTop: "10px",
                      textAlign: "center",
                    }}
                  >
                    <h3
                      style={{
                        fontSize: "1rem",
                        lineHeight: "1.2",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <Link to={`/visit-store/${item.store_uuid}`}>
                        {item.store_name}
                      </Link>
                    </h3>
                    <h4
                      style={{
                        fontSize: "0.875rem",
                        color: "#666",
                        lineHeight: "1.2",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {item.video_description}
                    </h4>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}

        <Modal
          isOpen={!!selectedVideo}
          onClose={closeModal}
          videoUrl={selectedVideo}
          videoData={selectedProductLinks}
        />
      </div>
    </section>
  );
};

export default LiveSocial;
